export const GET_SE_REQUEST = 'GET_SE_REQUEST'
export const GET_SE_REQUEST_SUCCESS = 'GET_SE_REQUEST_SUCCESS'
export const GET_SE_REQUEST_ERROR = 'GET_SE_REQUEST_ERROR'

export const CHECK_CONVERSATION_ID_SUCCESS = 'CHECK_CONVERSATION_ID_SUCCESS'
export const CHECK_CONVERSATION_ID_ERROR = 'CHECK_CONVERSATION_ID_ERROR'

export const GET_SE_REQUEST_FROM_FILE = 'GET_SE_REQUEST_FROM_FILE'

export const SAVE_SE_REQUEST = 'SAVE_SE_REQUEST'
export const SAVE_SE_REQUEST_SUCCESS = 'SAVE_SE_REQUEST_SUCCESS'
export const SAVE_SE_REQUEST_ERROR = 'SAVE_SE_REQUEST_ERROR'

export const EVAL_SE_REQUEST = 'EVAL_SE_REQUEST'
export const EVAL_SE_REQUEST_SUCCESS = 'EVAL_SE_REQUEST_SUCCESS'
export const EVAL_SE_REQUEST_ERROR = 'EVAL_SE_REQUEST_ERROR'

export const SET_STATE_DATA = 'SET_STATE_DATA'
export const SET_REQUEST_DATA = 'SET_REQUEST_DATA'
export const SET_TAX_FORM_DATA = 'SET_TAX_FORM_DATA'
export const SET_EXTRA_DATA = 'SET_EXTRA_DATA'

export const RETAIN_TAX_DATA = 'RETAIN_TAX_DATA'
export const DELETE_TAX_FORM = 'DELETE_TAX_FORM'
export const RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS = 'RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS'
export const DESELECT_BUSINESS_FORMS_PY = 'DESELECT_BUSINESS_FORMS_PY'

export const CREATE_CASEFILE_ASSOCIATION = 'CREATE_CASEFILE_ASSOCIATION'
export const REMOVE_CASEFILE_ASSOCIATION = 'REMOVE_CASEFILE_ASSOCIATION'

export const SKIP_YEAR_TAX_FORMS = 'SKIP_YEAR_TAX_FORMS'
export const SKIP_BUSINESS_TAX_FORMS = 'SKIP_BUSINESS_TAX_FORMS'

export const GET_SE_CASEFILE = 'GET_SE_CASEFILE'
export const GET_SE_CASEFILE_SUCCESS = 'GET_SE_CASEFILE_SUCCESS'

export const RESET_STATE = 'RESET_STATE'
export const RESET_SELECTED_TAX_FORMS = 'RESET_SELECTED_TAX_FORMS'
export const COPY_SELECTED_TAX_FORMS_PY_TO_CY = 'COPY_SELECTED_TAX_FORMS_PY_TO_CY'
export const PURGE_HIDDEN_TAX_FORMS = 'PURGE_HIDDEN_TAX_FORMS'

export const CHECK_CONVERSATION_ID = 'CHECK_CONVERSATION_ID'
export const SET_CLONED_DATA = 'SET_CLONED_DATA'
