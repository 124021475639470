<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
      Select Tax Year
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <h4 class="mt-3">Has the borrower filed tax returns for tax year {{lastTaxYear}}?</h4>

    <radio :label="true"
           :disabled="isLocked"
           v-model ="lastYearTaxReturnFiled">Yes</radio>

    <radio :label="false"
           :disabled="isLocked"
           v-model="lastYearTaxReturnFiled">No</radio>

    <div class="bp-help is-error" v-if="$v.lastYearTaxReturnFiled.$error">
      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
      </div>
      <template v-if="!$v.lastYearTaxReturnFiled.required">Please make a selection</template>
    </div>

    <se-help :visible.sync="showPageHelp" title="Learn More">
      <p>
        Use this screen to provide information about the most recent tax return filed.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-arrow-right/></icon-base>
        Select <b>Yes</b> if the borrower has filed their tax returns for the most recent calendar year. For example,
        if the current date is February 19, 2024, and the borrower has filed their 2023 federal income tax return, select Yes.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-arrow-right/></icon-base>
        Select <b>No</b> if the borrower has not filed their tax returns for the most recent calendar year.
        For example, if the current date is February 19, 2024, and the borrower has not filed their 2023 federal income tax return, select No.
      </p>
      <b>FAQs:</b>
      <p>
        Q. The borrower filed their federal income tax returns during loan processing. Do I need to update {{ appName }}?<br>
        A. Yes. The lender must use the data from the most recently filed tax return(s) to determine the borrower’s qualifying income.
      </p>
      <p>
        Q. The borrower filed IRS Form 4868 (Application for Automatic Extension of Time to File U.S.
        Individual Income Tax Return). Does {{ appName }} evaluate income when the borrower has
        filed an extension?<br>
        A. Yes. However, {{ appName }} does not require or evaluate data from IRS Form 4868 nor is it aware of the loan’s
        disbursement date, therefore the lender should pay careful attention to the application date,
        disbursement date, and documentation requirements outlined in the Fannie Mae Selling Guide.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about the Allowable Age of Federal Income Tax Returns in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b1-1-03/allowable-age-credit-documents-and-federal-income-tax-returns">Allowable Age of Credit Documents and Federal Income Tax Returns</a> section of the Fannie Mae Selling Guide.
      </p>
      <p>
        Q. The borrower’s business uses a fiscal year that does not end in December. Does {{ appName }}
        evaluate income under this scenario?<br>
        A. No. {{ appName }} does not currently evaluate income when the borrower’s business uses a
        fiscal year that does not end in December. You will need to use another method to calculate the borrower’s income.
      </p>
    </se-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>
  </div>
  </div>

</template>
<script>
import {Radio} from 'src/components/index'
import {IconFlag, IconBook, IconArrowRight} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import {mapGetters, mapState} from 'vuex'
import {
  PURGE_HIDDEN_TAX_FORMS,
  RESET_SELECTED_TAX_FORMS,
  COPY_SELECTED_TAX_FORMS_PY_TO_CY,
  SET_EXTRA_DATA
} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'
import {required, requiredIf} from 'vuelidate/lib/validators'
import appNameMixin from '../../../mixins/AppNameMixin'
import Swal from "sweetalert2";
import Enums from "@/model/enums";

export default {
  mixins:[appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    IconFlag, IconBook, IconArrowRight
  },
  data() {
    return {
      previousLatestTaxYearFiled: null,
      showPageHelp: false
    }
  },
  validations: {
    lastYearTaxReturnFiled: {
      required: required
    }
  },
  created() {
    this.Enums = Enums
    this.previousLatestTaxYearFiled = this.lastYearTaxReturnFiled
  },
  beforeRouteLeave(to, from, next) {
    if (this.isRentalScheduleE
        && this.previousLatestTaxYearFiled !== null
        && this.lastYearTaxReturnFiled !== this.previousLatestTaxYearFiled) {
      this.alertLatestTaxYearChanged(next)

    } else if (this.previousLatestTaxYearFiled === false
        && this.lastYearTaxReturnFiled === true
        && this.hasPY3Data) {
      this.alertOutdatedDataRemoved(next)

    } else if (this.previousLatestTaxYearFiled === false
        && this.lastYearTaxReturnFiled === true
        && this.hasPY2Data) {
      this.$store.commit(COPY_SELECTED_TAX_FORMS_PY_TO_CY, {py1: this.getPY1, py2: this.getPY2})
      next()

    } else {
      next()
    }
  },
  computed: {
    lastYearTaxReturnFiled: {
      get () {  return this.seRequest.extraData.lastYearTaxReturnFiled },
      set (lastYearTaxReturnFiled) { this.$store.commit(SET_EXTRA_DATA, {lastYearTaxReturnFiled})  }
    },
    lastTaxYear() {
      return this.getNewDate.getFullYear() - 1
    },
    ...mapState({
      seRequest: state => state.se.request
    }),
    ...mapGetters([
      'isLocked',
      'isDisplayBusScreens',
      'isRentalScheduleE',
      'getPY1',
      'getPY2',
      'hasPY2Data',
      'hasPY3Data',
      'getNewDate'
    ])
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$router.push('tax-form-select')
    },
    previous() {
      this.$router.push(this.isDisplayBusScreens ? 'business-structure' : 'du-evaluation')
    },
    alertLatestTaxYearChanged(next) {
      let msgIcon = "warning"
      let msgHtml = "<p>All Individual (personal) and rental tax return data will be permanently removed.</p>"

      Swal.fire({
        title: 'WARNING!',
        html: msgHtml,
        width: 480,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Change Tax Year`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(RESET_SELECTED_TAX_FORMS)
          this.$store.dispatch(PURGE_HIDDEN_TAX_FORMS)
          next(true)
        }
      })
    },
    alertOutdatedDataRemoved(next) {
      let msgIcon = "warning"
      let msgHtml = "<p>Outdated tax return data will be permanently removed</p>"

      Swal.fire({
        title: 'WARNING!',
        html: msgHtml,
        width: 480,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(COPY_SELECTED_TAX_FORMS_PY_TO_CY, {py1: this.getPY1, py2: this.getPY2})
          this.$store.dispatch(PURGE_HIDDEN_TAX_FORMS)
          next(true)
        }
      })
    },


  }
}
</script>
<style>


</style>
