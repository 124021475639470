import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'

// Components pages
import Welcome from 'src/pages/Dashboard/Components/Welcome.vue'
import SeLearnMore from 'src/pages/Dashboard/Components/SeLearnMore.vue'
import SeStartRequest from 'src/pages/Dashboard/Components/SeStartRequest.vue'
import SeDuEvaluation from "../pages/Dashboard/Components/SeDuEvaluation";
import SeBusinessName from "../pages/Dashboard/Components/SeBusinessName";
import SeBusinessLength from "../pages/Dashboard/Components/SeBusinessLength";
import SeBusinessStructure from "../pages/Dashboard/Components/SeBusinessStructure";
import SeTaxFormSelect from "../pages/Dashboard/Components/SeTaxFormSelect";
import SeEvalFindings from "../pages/Dashboard/Components/SeEvalFindings";
import SeStdTaxForm1040sc from "../pages/Dashboard/Components/SeStdTaxForm1040sc";
import SeIncomeType from "../pages/Dashboard/Components/SeIncomeType";
import SeTaxYear from "../pages/Dashboard/Components/SeTaxYear";
import SeStdTaxForm1040 from "../pages/Dashboard/Components/SeStdTaxForm1040";
import SeStdTaxForm1040s1 from "../pages/Dashboard/Components/SeStdTaxForm1040s1";
import SeStdTaxForm1040se from "../pages/Dashboard/Components/SeStdTaxForm1040se";
import Enums from "../model/enums";
import SeStdTaxForm4684 from "../pages/Dashboard/Components/SeStdTaxForm4684";
import SeStdTaxForm4562 from "../pages/Dashboard/Components/SeStdTaxForm4562";
import SeStdTaxFormW2 from "../pages/Dashboard/Components/SeStdTaxFormW2";
import SeStdTaxForm1120s from "../pages/Dashboard/Components/SeStdTaxForm1120s";
import SeStdTaxForm1120ssk from "../pages/Dashboard/Components/SeStdTaxForm1120ssk";
import SeStdTaxForm8825 from "../pages/Dashboard/Components/SeStdTaxForm8825";
import SeStdTaxForm1120sm3 from "../pages/Dashboard/Components/SeStdTaxForm1120sm3";
import SeStdTaxForm1065sk1 from "../pages/Dashboard/Components/SeStdTaxForm1065sk1";
import SeStdTaxForm1065 from "../pages/Dashboard/Components/SeStdTaxForm1065";
import SeStdTaxForm1065sm3 from "../pages/Dashboard/Components/SeStdTaxForm1065sm3";
import SeStdTaxFormLossesExpenses from "../pages/Dashboard/Components/SeStdTaxFormLossesExpenses";
import SeStdTaxForm1125e from "../pages/Dashboard/Components/SeStdTaxForm1125e";
import SeStdTaxForm1120 from "../pages/Dashboard/Components/SeStdTaxForm1120";
import SeStdTaxForm1120m3 from "../pages/Dashboard/Components/SeStdTaxForm1120m3";


let homeMenu = {
  path: '/home',
  component: DashboardLayout,
  redirect: '/home/welcome',
  children: [
    {
      path: 'welcome/:conversationId?',
      name: 'Welcome',
      component: Welcome,
    },
    {
      path: 'learn-more',
      name: 'Learn More',
      component: SeLearnMore,
    }
  ]
}


let requestMenu = {
  path: '/se-eval',
  component: DashboardLayout,
  redirect: '/se-eval/start',
  children: [
    {
      path: 'start',
      name: 'Start Request',
      component: SeStartRequest
    },
    {
      path: 'income-type',
      name: 'Income TYpe',
      component: SeIncomeType
    },
    {
      path: 'business-name',
      name: 'Reference Name',
      component: SeBusinessName
    },
    {
      path: 'du-evaluation',
      name: 'Desktop Underwriter',
      component: SeDuEvaluation
    },
    {
      path: 'business-structure',
      name: 'Business Structure',
      component: SeBusinessStructure
    },
    {
      path: 'tax-year',
      name: 'Select Tax Year',
      component: SeTaxYear
    },
    {
      path: 'business-length',
      name: 'Length of Employment',
      component: SeBusinessLength
    },
    {
      path: 'tax-form-select',
      name: 'Select Tax Forms',
      component: SeTaxFormSelect
    },
    {
      path: 'eval-findings',
      name: 'Findings',
      component: SeEvalFindings
    },
    {
      path: 'std-tax-form-1040/:formName/:year',
      name: 'Form 1040',
      component: SeStdTaxForm1040
    },
    {
      path: 'std-tax-form-1040s1/:formName/:year',
      name: 'Form 1040 Sch 1',
      component: SeStdTaxForm1040s1
    },
    {
      path: 'std-tax-form-1040se/:formName/:year/:part',
      name: 'Form 1040 Sch E',
      component: SeStdTaxForm1040se
    },
    {
      path: 'std-tax-form-4684/:formName/:year',
      name: 'Form 4684',
      component: SeStdTaxForm4684
    },
    {
      path: 'std-tax-form-1040sc/:formName/:year/:part',
      name: 'Form 1040 Sch C-Pg1',
      component: SeStdTaxForm1040sc
    },
    {
      path: 'std-tax-form-4562/:formName/:year/:part',
      name: 'Form 4562',
      component: SeStdTaxForm4562
    },
    {
      path: 'std-tax-form-w2/:formName/:year',
      name: 'W2',
      component: SeStdTaxFormW2
    },
    {
      path: 'std-tax-form-1120s/:formName/:year/:part',
      name: 'Form 1120S',
      component: SeStdTaxForm1120s
    },
    {
      path: 'std-tax-form-1120ssk/:formName/:year',
      name: 'Form 1120S Sch K-1',
      component: SeStdTaxForm1120ssk
    },
    {
      path: 'std-tax-form-8825/:formName/:year',
      name: 'Form 8825',
      component: SeStdTaxForm8825
    },
    {
      path: 'std-tax-form-1120sm3/:formName/:year',
      name: 'Form 1120-S Schedule M-3',
      component: SeStdTaxForm1120sm3
    },
    {
      path: 'std-tax-form-1065sk1/:formName/:year',
      name: 'Form 1065 Sch K-1',
      component: SeStdTaxForm1065sk1
    },
    {
      path: 'std-tax-form-1065/:formName/:year/:part',
      name: 'Form 1065',
      component: SeStdTaxForm1065
    },
    {
      path: 'std-tax-form-1065sm3/:formName/:year',
      name: 'Form 1065 Schedule M-3',
      component: SeStdTaxForm1065sm3
    },
    {
      path: 'std-tax-form-losses-expenses/:formName/:year',
      name: 'Losses and Expenses',
      component: SeStdTaxFormLossesExpenses
    },
    {
      path: 'std-tax-form-1125e/:formName/:year',
      name: 'Form 11250-E',
      component: SeStdTaxForm1125e
    },
    {
      path: 'std-tax-form-1120/:formName/:year/:part',
      name: 'Form 1120',
      component: SeStdTaxForm1120
    },
    {
      path: 'std-tax-form-1120m3/:formName/:year',
      name: 'Form 1120 Schedule M-3',
      component: SeStdTaxForm1120m3
    },

  ]
}


const routes = [
  {
    path: '/',
    redirect: '/home/welcome'
  },
  homeMenu,
  requestMenu,
  {path: '*', component: NotFound}
]

export default routes
