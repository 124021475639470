import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1120ssk,
  pdf: `static/forms/${ty}/${ty}_1120ssk.pdf`,
  route: `/se-eval/std-tax-form-1120ssk/${Enums.SeTaxFormType.tf1120ssk}/${ty}`,
  fields: [
    {
      id: "isBorrowerTrustGrantor",
      fieldName: "",
      label: "If Line F3 or other information  indicates the Shareholder’s name is a trust, make the appropriate selection",
      isBoolean: true,
      line: "If Line F3 or other information  indicates the Shareholder’s name is a trust, make the appropriate selection",
      hasHelp: true,
      isEmptyByDefault: "",
      pageHeader: "<table class='el-table el-table--mini w-75'><tr><td><b>Loan Application</b></td><td><b>K-1</b></td></tr><tr><td>Borrower's Social Security Number</td><td>Box E (Shareholder's identifying number)</td></tr><tr><td>Borrower's Name</td><td>Box F (Shareholder's name)</td></tr></table>"
    },
    {
      id: "taxPeriodEndDate",
      fieldName: "topmostSubform[0].Page1[0].Header[0].f1_03[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].Header[0].ForCalendarYear[0].f1_03[0]",
        "topmostSubform[0].Page1[0].Header[0].ForCalendarYear[0].f1_04[0]",
        "topmostSubform[0].Page1[0].Header[0].ForCalendarYear[0].f1_05[0]"
      ],
      label: "Tax year ending",
      line: "Header",
      isCurrency: false,
      isPositive: false,
      isEmptyByDefault: true,
      hasHelp: true
    },
    {
      id: "isFinalK1",
      fieldName: "topmostSubform[0].Page1[0].c1_01[0]",
      label: "Final K-1",
      isBoolean: true,
      required: true,
      line: "Final K-1",
      hasHelp: true,
      defaultValue: "No"
    },
    {
      id: "isFinalReturnOverride",
      fieldName: "",
      label: "Final return",
      isBoolean: true,
      required: true,
      line: "Final return",
      hasHelp: false,
      defaultValue: "Yes"
    },
    {
      id: "cyAllocationPercentage",
      fieldName: "topmostSubform[0].Page1[0].LeftCol[0].f1_16[0]",
      label: "Current year allocation percentage",
      line: "Line G",
      isCurrency: false,
      isPositive: true,
      isPercent: true,
      hasHelp: true
    },
    {
      id: "ordinaryBusinessIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].Lines1-12[0].f1_21[0]",
      label: "Ordinary business income (loss)",
      line: "Line 1",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "netRentalRealEstateIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].Lines1-12[0].f1_22[0]",
      label: "Net rental real estate income (loss)",
      line: "Line 2",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "otherNetRentalIncomeLoss",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].Lines1-12[0].f1_23[0]",
      label: "Other net rental income (loss)",
      line: "Line 3",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "ordinaryDividends",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].Lines1-12[0].f1_25[0]",
      label: "Ordinary dividends",
      line: "Line 5a",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "itemsAffectingShareholderBasisCodeD",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_80[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_80[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_82[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_84[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_86[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_88[0]"
      ],
      label: "Items affecting shareholder basis",
      line: "Line 16",
      isBoolean: true,
      isCurrency: false,
      defaultValue: "Yes"
    },
    {
      id: "itemsAffectingShareholderBasisCodeDAmount",
      fieldName: "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_80[0]",
      fieldHighlights: [
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_80[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_82[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_84[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_86[0]",
        "topmostSubform[0].Page1[0].RightCol[0].Lines13-17[0].f1_88[0]"
      ],
      label: "Code D, Line 16 amount",
      line: "Line 16 D",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "isBusinessLiquidityAdequate",
      fieldName: "",
      label: "Business liquidity adequate confirmation",
      isBoolean: true,
      line: "Business liquidity adequate confirmation",
      hasHelp: false,
      defaultValue: "Yes"
    },
  ]
}

