<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 4684 ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <h4 class="mt-3">Find Section B, Part II of Form 4684 (page 2)</h4>

          <h4 class="mt-3">If the borrower owns more than one business or rental property, ensure the amounts provided relate to the business or rental property you want {{appName}} to evaluate.</h4>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

                <label :for="row.id" class="se-std-field-group-label">
                  <span>{{row.line}}</span>
                </label>
                <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                  <i class="fa fa-info-circle"></i>
                </a>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <radio :label="false"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1">
                    <span class="se-std-label">Enter {{row.name}} for this business</span>
                  </radio>
                  <input :id="row.id"
                         :readonly="isLocked"
                         class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                         type="text"
                         v-mask="row.mask"
                         @focus="highlightFocusedFormField(row)"
                         @blur="fieldIsFocused = false"
                         :disabled="row.isEmpty || isSkipped[index]"
                         v-model="row.value" />
                  <radio :label="true"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1"><span class="se-std-label">There is no amount on this line for this business</span>
                  </radio>
                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>

      </div>

    <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information about Casualties and Thefts.
        </p>
      <p>
        {{ appName }} will determine if there is a loss amount due to a casualty or theft event. Any loss amount will be added back to monthly qualifying income. 
        If the borrower owns more than one business or rental property, ensure the amounts provided relate to the business or rental property you want {{ appName }} to evaluate.
      </p>
      <b>FAQs</b>
      <p>
        Q. The borrower owns more than one business or rental property. How do I determine what Casualty or Theft loss amounts are associated to the business 
        or rental property I want {{ appName }} to evaluate?<br>
        A. There may be an attachment in the tax return that lists the amounts for each business or rental property. You may ask the borrower or tax preparer for this information.
        Because Casualty and Theft losses are added back to the monthly qualifying income, the amount entered should be supported by documentation in the loan file.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftNonIncomePropertyShortTermGainLossAmount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} will determine if there is a loss amount due to a casualty or theft event. Any loss amount will
          be added back to the monthly qualifying income. If the borrower owns more than one business or rental property, ensure the amounts provided
          relate to the business or rental property you want {{ appName }} to evaluate.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Even borrowers who experience a casualty or theft event may not have business or rental property gains or losses to report.
        </p>
        <p>
          Q. The borrower owns more than one business or rental property. How do I determine what Casualty or Theft loss amounts are associated to the business 
        or rental property I want {{ appName }} to evaluate?<br>
        A. There may be an attachment in the tax return that lists the amounts for each business or rental property. You may ask the borrower or tax preparer for this information.
        Because Casualty and Theft losses are added back to the monthly qualifying income, the amount entered should be supported by documentation in the loan file.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftIncomePropertyShortTermLossAmount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} will determine if there is a loss amount due to a casualty or theft event. Any loss amount will
          be added back to the monthly qualifying income. If the borrower owns more than one business or rental property, ensure the amounts provided
          relate to the business or rental property you want {{ appName }} to evaluate.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Even borrowers who experience a casualty or theft event may not have business or rental property gains or losses to report.
        </p>
        <p>
          Q. The borrower owns more than one business or rental property. How do I determine what Casualty or Theft loss amounts are associated to the business 
        or rental property I want {{ appName }} to evaluate?<br>
        A. There may be an attachment in the tax return that lists the amounts for each business or rental property. You may ask the borrower or tax preparer for this information.
        Because Casualty and Theft losses are added back to the monthly qualifying income, the amount entered should be supported by documentation in the loan file.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftLongTermGainAmount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} will determine if there is a loss amount due to a casualty or theft event. Any loss amount will
          be added back to the monthly qualifying income. If the borrower owns more than one business or rental property, ensure the amounts provided
          relate to the business or rental property you want {{ appName }} to evaluate.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Even borrowers who experience a casualty or theft event may not have business or rental property gains or losses to report.
        </p>
        <p>
         Q. The borrower owns more than one business or rental property. How do I determine what Casualty or Theft loss amounts are associated to the business 
        or rental property I want {{ appName }} to evaluate?<br>
        A. There may be an attachment in the tax return that lists the amounts for each business or rental property. You may ask the borrower or tax preparer for this information.
        Because Casualty and Theft losses are added back to the monthly qualifying income, the amount entered should be supported by documentation in the loan file.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.casualtyTheftLongTermLossAmount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} will determine if there is a loss amount due to a casualty or theft event. Any loss amount will
          be added back to the monthly qualifying income. If the borrower owns more than one business or rental property, ensure the amounts provided
          relate to the business or rental property you want {{ appName }} to evaluate.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. Yes. Even borrowers who experience a casualty or theft event may not have business or rental property gains or losses to report.
        </p>
        <p>
         Q. The borrower owns more than one business or rental property. How do I determine what Casualty or Theft loss amounts are associated to the business 
        or rental property I want {{ appName }} to evaluate?<br>
        A. There may be an attachment in the tax return that lists the amounts for each business or rental property. You may ask the borrower or tax preparer for this information.
        Because Casualty and Theft losses are added back to the monthly qualifying income, the amount entered should be supported by documentation in the loan file.
        </p>
      </div>
    </se-help>


    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin';


export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true

        return false;
      })
    },
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
