<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
      Income Type
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>

    <div>
      <radio :label="Enums.SeIncomeTypeType.selfEmployed"
             :disabled="isLocked"
             v-model="incomeType">Self-Employed or Business Owner</radio>

      <radio :label="Enums.SeIncomeTypeType.rental"
             :disabled="isLocked"
             v-model="incomeType">Rental</radio>

      <div class="bp-help is-error" v-if="$v.incomeType.$error">
        <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
        </div>
        <template v-if="!$v.incomeType.required">Please make a selection</template>
      </div>
    </div>

    <div class="mt-5" v-if="incomeType === Enums.SeIncomeTypeType.rental">
      <h4 class="mt-3 mb-3">Thinking about the rental income you want Income Calculator to evaluate, describe the method used by the borrower to report this rental income to the IRS:</h4>

      <radio :label="Enums.SeRentalIncomeIrsFormType.scheduleEPart1"
             :disabled="isLocked"
             v-model="rentalIncomeIrsFormType">Rental income is reported to the IRS using Form 1040, Schedule E, Part 1
            <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpScheduleEPart1 = true"><i class="fa fa-info-circle"></i></a>
      </radio>
      <radio :label="Enums.SeRentalIncomeIrsFormType.businessTaxReturns"
             :disabled="true"
             v-model="rentalIncomeIrsFormType">Rental income is reported to the IRS using business tax returns (Form 1065 or Form 1120S)
            <a class="btn-link se-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelpBusinessTaxReturns = true"><i class="fa fa-info-circle"></i></a>
      </radio>
      <div class="bp-help is-error" v-if="$v.rentalIncomeIrsFormType.$error">
        <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
        </div>
        <template v-if="!$v.rentalIncomeIrsFormType.required">Please make a selection</template>
      </div>
    </div>

    <se-help :visible.sync="showHelp" title="Learn More">
      <p>
        Use this screen to tell {{ appName }} the kind of income you want it to evaluate.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-arrow-right/></icon-base>
        Select <b>Self-Employed or Business Owner</b> if you want {{ appName }} to evaluate self-employment or business ownership income from a Sole Proprietorship (Schedule C), 
        Partnership, S-Corporation (S-Corp), standard Corporation (C-Corp), or Limited Liability Company (LLC).<br>
		<icon-base width="22" height="22" iconColor="#0c77ba"><icon-arrow-right/></icon-base>
        Select <b>Rental</b> if you want {{ appName }} to evaluate rental property income from a Schedule E, Part I of the borrower's personal tax returns.
      </p>
      <b>FAQs:</b>
      <p>
      Q. Can {{ appName }} evaluate self-employment income or losses generated from rental properties reported to the IRS through a Partnership or S-Corporation?<br>
	  A. Not at this time.
      </p>
      <p>
        Q. Will I need to complete a separate {{ appName }} evaluation for each business or rental property?<br>
	    A. Yes. If the borrower has multiple businesses or rental properties, you will need to complete a separate {{ appName }} evaluation for each.<br>
      </p>
      <p>
        For example, if the borrower is a partner in a tree growing business, is also a partner in a
        landscape design business, and files separate business tax returns for each business, then you
        will need two separate {{ appName }} evaluations; one for each business.
      </p>
      <p>
        Q. There are two borrowers on the loan. The borrowers are each 50% owners of the Partnership,
        S-Corporation, Corporation, or LLC. Will I need to complete a separate {{ appName }} evaluation for each borrower?<br>
        A. Yes. Completing separate income evaluations is <b>required</b> for each borrower.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpScheduleEPart1" title="Learn More">
      <p>
        Review Schedule E of Form 1040. Make this selection if the rental property you want to evaluate is reported on Schedule E, Part 1.
      </p>
    </se-help>

    <se-help :visible.sync="showHelpBusinessTaxReturns" title="Learn More">
      <p>
        {{ appName }} does not currently support evaluation of self-employment income generated from rental property.
      </p>
    </se-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>
    </div>
  </div>

</template>
<script>
import {Radio, IconBook, IconArrowRight} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import Swal from 'sweetalert2'
import {mapGetters, mapState} from 'vuex'
import Enums from 'src/model/enums'
import {
  RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS,
  RETAIN_TAX_DATA,
  SET_EXTRA_DATA,
  SET_REQUEST_DATA
} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'
import {required, requiredIf} from 'vuelidate/lib/validators'
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
  mixins: [appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    IconBook, IconArrowRight
  },
  data() {
    return {
      previousIncomeType: null,
      showHelp: false,
      showHelpScheduleEPart1: false,
      showHelpBusinessTaxReturns: false
    }
  },
  validations: {
    incomeType: {
      required: required
    },
    rentalIncomeIrsFormType: {
      required: requiredIf( function () { return this.incomeType === Enums.SeIncomeTypeType.rental })
    }
  },
  created() {
    this.Enums = Enums
    this.previousIncomeType = this.incomeType
  },
  beforeRouteLeave(to, from, next) {
    if (this.seRequest.requestId
        && this.incomeType !== this.previousIncomeType
        && this.previousIncomeType !== null) {
      this.alertIncomeTypeChanged(next)
    } else {
      next()
    }
  },
  computed: {
    incomeType: {
      get () {
        return this.seRequest.incomeType
      },
      set (incomeType) {
        this.$store.commit(SET_REQUEST_DATA, {incomeType})
        if (incomeType === Enums.SeIncomeTypeType.selfEmployed) {
          this.rentalIncomeIrsFormType = null;
        }
      }
    },
    rentalIncomeIrsFormType: {
      get () {
        return this.seRequest.extraData.rentalIncomeIrsFormType
      },
      set (rentalIncomeIrsFormType) {
        this.$store.commit(SET_EXTRA_DATA, {rentalIncomeIrsFormType})
        if (rentalIncomeIrsFormType === Enums.SeRentalIncomeIrsFormType.businessTaxReturns
          && [Enums.SeBusinessStructureType.rental.id, Enums.SeBusinessStructureType.soleProprietorship.id].includes(this.seRequest.extraData.businessStructureProvided)) {
            //change default bus structure to scorp and retain related forms
            this.$store.commit(SET_EXTRA_DATA, {businessStructureProvided: Enums.SeBusinessStructureType.sCorp.id})
            this.$store.commit(RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS)

        } else if (rentalIncomeIrsFormType === Enums.SeRentalIncomeIrsFormType.scheduleEPart1
          && this.seRequest.extraData.businessStructureProvided !== Enums.SeBusinessStructureType.rental.id) {
          //change default bus structure to sole and retain related forms
          this.$store.commit(SET_EXTRA_DATA, {businessStructureProvided: Enums.SeBusinessStructureType.rental.id})
          this.$store.commit(RETAIN_BUS_STRUCT_COMPATIBLE_TAX_FORMS)

        }
      }
    },

    ...mapState({
      seRequest: state => state.se.request
    }),
    ...mapGetters([
      'isLocked',
      'getPY1'
    ])
  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$router.push('business-name')
    },
    previous() {
      this.$router.push('start')
    },

    alertIncomeTypeChanged(next) {
      let msgIcon = "warning"
      let msgHtml = "<p>All rental and business data will be permanently removed. Individual (personal) tax return data will be retained.</p>"

      Swal.fire({
        title: 'WARNING!',
        html: msgHtml,
        width: 480,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `Change Income Type`,
        cancelButtonText: `Cancel`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(RETAIN_TAX_DATA, [{'year': this.getPY1, 'id': Enums.SeTaxFormType.tf1040}])
          next(true)
        }
      })
    }

  }
}
</script>
<style>


</style>
