<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Losses and Expenses ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

              <template v-if="index === 0">
                <p v-if="isRental">
                  Some borrowers have certain uncommon property losses or expenses that can be added back when calculating the rental property's income or loss. If you think the borrower may have these kinds of property losses or expenses, continue reviewing the information below; otherwise click “Continue”.
                </p>
                <p v-else>
                  Some borrowers have certain uncommon business losses or expenses that can be added back to the borrower’s monthly qualifying income. If you think the borrower may have these kinds of business losses or expenses, continue reviewing the information below; otherwise click “Continue”.
                </p>
              </template>

              <label :for="row.id" class="se-std-field-group-label mt-3">
                <span>{{row.line}}</span>
              </label>
              <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                <i class="fa fa-info-circle"></i>
              </a>

              <template v-if="row.id === 'extraordinaryOneTimeExpenses'">
                <p class="mt-3" v-if="isRental">
                  The lender may determine certain property expenses are extraordinary one-time expenses. There is no one specific location on the tax return where extraordinary one-time expenses are identified. The lender’s underwriting analysis must describe the circumstances that justify the extraordinary and one-time nature of the expense. The lender may request documentation from the borrower to support its determination that the expense is extraordinary and nonrecurring.
                </p>
                <p class="mt-3" v-else>
                  The lender may determine certain expenses, such as a carryover loss from a prior year are extraordinary one-time expenses. There is no one specific location on the tax return where extraordinary one-time expenses are identified. The lender’s underwriting analysis must describe the circumstances that justify the extraordinary and one-time nature of the expense. The lender may request documentation from the borrower to support its determination that the expense is extraordinary and nonrecurring.
                </p>
              </template>

              <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">

                <template v-if="row.id === 'form4684Included'">
                  <p class="mt-3" v-if="isRental">
                    Losses due to a major federally declared disaster (also known as a casualty), such as a hurricane or earthquake are reported in detail on IRS Form 4684 Casualties and Thefts. Look for this form in the tax returns.
                  </p>
                  <p class="mt-3" v-else>
                    Losses from theft of business property and losses of business property due to a major federally declared disaster (also known as a casualty), such as a hurricane or earthquake are reported in detail on IRS Form 4684 Casualties and Thefts. Look for this form in the tax returns.
                  </p>
                  <radio label="Yes"
                         v-model="row.value"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1"><span class="se-std-label">Yes, Form 4684 is included in the tax returns</span>
                  </radio>
                  <radio label="No"
                         v-model="row.value"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1"><span class="se-std-label">No, Form 4684 is not included in the tax returns</span>
                  </radio>
                </template>

                <template v-else-if = "row.id === 'extraordinaryOneTimeExpenses'">
                  <radio :label="false"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1">
                    <span class="se-std-label">Enter {{row.name}}</span>
                  </radio>
                  <input :id="row.id"
                         :readonly="isLocked"
                         class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                         type="text"
                         v-mask="row.mask"
                         @focus="highlightFocusedFormField(row)"
                         @blur="fieldIsFocused = false"
                         :disabled="row.isEmpty || isSkipped[index]"
                         v-model="row.value" />
                  <radio :label="true"
                         v-model="row.isEmpty"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1"><span class="se-std-label">There is no Extraordinary One-Time Expense</span>
                  </radio>
                </template>

              </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>

      </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information about Casualty and Theft Losses or Extraordinary One-Time Expenses. 
        If the borrower owns more than one business or rental property, ensure the amounts provided relate to the business or rental property you want 
        {{ appName }} to evaluate. Any loss amounts will be added back to monthly qualifying income.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.form4684Included">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. Any Casualty or Theft loss amount will be added back to the monthly qualifying income. 
          If the borrower owns more than one business or rental property, ensure the amounts provided relate to the business or rental property you want {{ appName }} to evaluate.
        </p>
        <p>
          Q. There is no Form 4684 in the borrower’s tax return. Is that common?<br>
          A. Yes. Most borrowers do not have Casualty or Theft losses.
        </p>
        <p>
         Q. The borrower owns more than one business or rental property. How do I determine what Casualty or Theft loss amounts are associated 
          to the business or rental property I want {{ appName }} to evaluate?<br>
		 A. There may be an attachment in the tax return that lists the amounts for each business or rental property. 
          You may ask the borrower or tax preparer for this information. Because Casualty and Theft losses are added back to the monthly qualifying income, 
          the amount entered should be supported by documentation in the loan file.
        </p>
        <p>
          Q. There is a Form 4684 in the borrower’s tax returns. What data from that form am I supposed to report to {{ appName }}?<br>
          A. When you click the “Continue” button a new screen will display showing the data needed.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.extraordinaryOneTimeExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. Any Extraordinary one-time expense amount determined by the lender will be added back to the monthly qualifying income. 
          If the borrower owns more than one business or rental property, ensure the amounts provided relate to the business or rental 
          property you want {{ appName }} to evaluate.
        </p>
        <p>
          Q. There are no Extraordinary one-time expenses. Is that common?<br>
          A. Yes. Most borrowers do not have Extraordinary one-time expenses.
        </p>
        <p>
          Q. I have identified Extraordinary one-time expenses. How do I document this in the loan file?<br>
          A. The lender must note on the Transmittal Summary (Form 1008) or elsewhere in the loan file the
          circumstances that justify the extraordinary and one-time nature of the expense.
        </p>
        <p>
          Q. Does the borrower need to provide documentation supporting the lender’s decision?<br>
          A. Fannie Mae does not require the lender to request documentation from the borrower to support the lender’s determination
          that the expense is extraordinary and nonrecurring.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Analyzing Individual Tax Returns (and extraordinary one-time expenses) in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-01/general-information-analyzing-individual-tax-returns">General Information on Analyzing Individual Tax Returns</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin';
export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true

        return false;
      })
    },
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
