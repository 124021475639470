import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1120m3,
  waivable: true,
  pdf: `static/forms/${ty}/${ty}_1120m3.pdf`,
  route: `/se-eval/std-tax-form-1120m3/${Enums.SeTaxFormType.tf1120m3}/${ty}`,
  fields: [
    {
      id: "mealsAndEntertainment",
      fieldName: "topmostSubform[0].Page3[0].Part3_Table[0].Ln11_Row[0].f3_43[0]",
      label: "Meals and entertainment. Permanent difference",
      line: "Line 11(c)",
      isCurrency: true,
      isPositive: true,
      hasHelp: true,
      pageHeader: "Find Part III of Schedule M-3 (page 3)"
    }
  ]
}

