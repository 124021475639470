import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040se,
  parts: [
    {
      part: "1",
      pdf: `static/forms/${ty}/${ty}_1040sePg1.pdf`,
      route: `/se-eval/std-tax-form-1040se/${Enums.SeTaxFormType.tf1040se}/${ty}/1`,
      fields: [
        {
          pageHeader: "For the property you want Income Calculator to evaluate, find Part I of Schedule E",
          id: "rentalPropertyAddress",
          fieldName: "topmostSubform[0].Page1[0].Table_Line1a[0].RowA[0].f1_3[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Line1a[0].RowA[0].f1_3[0]",
            "topmostSubform[0].Page1[0].Table_Line1a[0].RowB[0].f1_4[0]",
            "topmostSubform[0].Page1[0].Table_Line1a[0].RowC[0].f1_5[0]"
          ],
          label: "Physical address of property",
          line: "Line 1a (optional) ",
          required: false,
          hasHelp: true,
        },
        {
          id: "isPrincipalResidence",
          fieldName: "",
          label: "Borrower’s principal residence (Occupancy)",
          line: "Borrower’s principal residence (Occupancy)",
          isBoolean: true,
          required: true,
          hasHelp: true
        },
        {
          id: "isBorrowerWithHousingExpense",
          fieldName: "",
          label: "Borrower Housing Expense",
          line: "Borrower Housing Expense",
          isBoolean: true,
          hasHelp: true,
          defaultValue: "Yes"
        },
        {
          id: "isSubjectProperty",
          fieldName: "",
          label: "Loan will refinance this property",
          line: "Loan will refinance this property",
          isBoolean: true,
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "rentalPropertyHousingPayment",
          fieldName: "",
          label: "Rental Property Housing Expense (PITIA)",
          line: "Rental Property Housing Expense (PITIA)",
          isCurrency: true,
          isPositive: true,
          required: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyType",
          fieldName: "topmostSubform[0].Page1[0].Table_Line1b[0].RowA[0].f1_6[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Line1b[0].RowA[0].f1_6[0]",
            "topmostSubform[0].Page1[0].Table_Line1b[0].RowB[0].f1_7[0]",
            "topmostSubform[0].Page1[0].Table_Line1b[0].RowC[0].f1_8[0]"
          ],
          label: "Type of Property",
          line: "Line 1b",
          hasHelp: true
        },
        {
          id: "isOtherPropertyTypeRentalIncome",
          fieldName: "",
          label: "Other Property Type",
          line: "Other Property Type",
          isBoolean: true,
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "fairRentalDaysCount",
          fieldName: "topmostSubform[0].Page1[0].Table_Line2[0].RowA[0].f1_9[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Line2[0].RowA[0].f1_9[0]",
            "topmostSubform[0].Page1[0].Table_Line2[0].RowB[0].f1_11[0]",
            "topmostSubform[0].Page1[0].Table_Line2[0].RowC[0].f1_13[0]"
          ],
          label: "Fair Rental Days",
          line: "Line 2",
          isInteger: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "isFullYearOrNoHistoryRental",
          fieldName: "",
          label: "Fair Rental Days Missing",
          line: "Fair Rental Days Missing",
          isBoolean: true,
          required: true,
          hasHelp: true
        },
        {
          id: "fairRentalDaysAltCount",
          fieldName: "",
          label: "Fair Rental Days from Alternative Source",
          line: "Fair Rental Days from Alternative Source",
          isInteger: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "fairRentalDaysLessThan365DetailType",
          fieldName: "",
          label: "Fair rental days less than 365 detail",
          line: "Fair rental days less than 365 detail",
          required: true,
          hasHelp: true
        },
        {
          id: "isBorrowerWithOneYearRentalPropertyOwnershipHistory",
          fieldName: "",
          label: "Rental property ownership history",
          line: "Rental property ownership history",
          isBoolean: true,
          required: true,
          hasHelp: true
        },
        {
          id: "rentsReceived",
          fieldName: "topmostSubform[0].Page1[0].Table_Income[0].Line3[0].f1_16[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Income[0].Line3[0].f1_16[0]",
            "topmostSubform[0].Page1[0].Table_Income[0].Line3[0].f1_17[0]",
            "topmostSubform[0].Page1[0].Table_Income[0].Line3[0].f1_18[0]"
          ],
          label: "Rents received",
          line: "Line 3",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyAccessoryBoarderIncome",
          fieldName: "",
          label: "the amount of accessory unit rental income or boarder income included in Line 3",
          line: "Accessory Unit or Boarder Income",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyInsurance",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line9[0].f1_34[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line9[0].f1_34[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line9[0].f1_35[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line9[0].f1_36[0]"
          ],
          label: "Insurance",
          line: "Line 9",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyMortgageInterestPaidToBanks",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line12[0].f1_43[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line12[0].f1_43[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line12[0].f1_44[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line12[0].f1_45[0]"
          ],
          label: "Mortgage interest paid to banks, etc.",
          line: "Line 12",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyOtherInterest",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line13[0].f1_46[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line13[0].f1_46[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line13[0].f1_47[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line13[0].f1_48[0]"
          ],
          label: "Other interest",
          line: "Line 13",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyOtherDeductibleMortgageInterest",
          fieldName: "",
          pageHeader: "Line 13 may contain deductible mortgage interest paid to a non-bank or split among multiple property owners. Look for an attachment that describes the Other interest amount shown on Line 13. If there is no attachment, the loan file must contain evidence that any amount shown below is deductible mortgage interest.",
          label: "the amount of deductible mortgage interest included in Line 13",
          line: "Other Deductible Mortgage Interest",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyRepairs",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line14[0].f1_49[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line14[0].f1_49[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line14[0].f1_50[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line14[0].f1_51[0]"
          ],
          label: "Repairs",
          line: "Line 14",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyTax",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line16[0].f1_55[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line16[0].f1_55[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line16[0].f1_56[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line16[0].f1_57[0]"
          ],
          label: "Taxes",
          line: "Line 16",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyIndividualPropertyDepreciation",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line18[0].f1_61[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line18[0].f1_61[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line18[0].f1_62[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line18[0].f1_63[0]"
          ],
          label: "Depreciation expense or depletion",
          line: "Line 18",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyTotalExpenses",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line20[0].f1_68[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line20[0].f1_68[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line20[0].f1_69[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line20[0].f1_70[0]"
          ],
          label: "Total expenses",
          line: "Line 20",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "rentalPropertyHomeownersAssociationDues",
          fieldName: "topmostSubform[0].Page1[0].Table_Expenses[0].Line7[0].f1_28[0]",
          fieldHighlights: [
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line7[0].f1_28[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line7[0].f1_29[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line7[0].f1_30[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line11[0].f1_40[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line11[0].f1_41[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line11[0].f1_42[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line19[0].f1_65[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line19[0].f1_66[0]",
            "topmostSubform[0].Page1[0].Table_Expenses[0].Line19[0].f1_67[0]"
          ],
          label: "homeowner’s association/condominium/cooperative dues",
          line: "Homeowners Association/Condo/Coop Dues",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        }
      ]
    },
    {
      part: "2",
      pdf: `static/forms/${ty}/${ty}_1040sePg2.pdf`,
      route: `/se-eval/std-tax-form-1040se/${Enums.SeTaxFormType.tf1040se}/${ty}/2`,
      fields: [
        {
          pageHeader: "Find part II of Schedule E (See Page 2)",
          id: "isPassiveLossOrUnreimbursedPartnershipExpense",
          fieldName: "topmostSubform[0].Page2[0].c2_1[0]",
          label: "Line 27",
          isBoolean: true,
          required: true,
          line: "Line 27",
          hasHelp: true
        },
        {
          id: "pOrS",
          fieldName: "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowA[0].f2_4[0]",
          fieldHighlights: [
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowA[0].f2_4[0]",
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowB[0].f2_7[0]",
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowC[0].f2_10[0]",
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowD[0].f2_13[0]"
          ],
          label: "P for partnership, S for S corporation",
          line: "Line 28(b)",
          radio: true,
          isCurrency: false,
          required: true,
          hasHelp: true
        },
        {
          id: "passiveActivityLoss",
          fieldName: "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowA[0].f2_15[0]",
          fieldHighlights: [
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowA[0].f2_15[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowB[0].f2_20[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowC[0].f2_25[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowD[0].f2_30[0]"
          ],
          label: "Passive Loss Allowed (do not enter a value if the name on Line 28(a) is “UPE” or “Unreimbursed Partnership Expense”)",
          line: "Line 28(g)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        },
        {
          id: "nonPassiveK1IncomeLoss",
          fieldName: "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowA[0].f2_19[0]",
          fieldHighlights: [
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowA[0].f2_19[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowB[0].f2_24[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowC[0].f2_29[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowD[0].f2_34[0]"
          ],
          label: "Nonpassive income from Schedule K-1 (do not enter a value if the name on Line 28(a) is “UPE” or “Unreimbursed Partnership Expense”)",
          line: "Line 28(k)",
          isCurrency: true,
          isPositive: false,
          hasHelp: true
        },
        {
          id: "isUnreimbursedPartnershipExpense",
          fieldName: "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowA[0].f2_3[0]",
          fieldHighlights: [
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowA[0].f2_3[0]",
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowB[0].f2_6[0]",
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowC[0].f2_9[0]",
            "topmostSubform[0].Page2[0].Table_Line28a-f[0].RowD[0].f2_12[0]"
          ],
          label: "Line 28(a) Name",
          isBoolean: true,
          required: true,
          line: "Line 28(a) Name",
          hasHelp: true,
          defaultValue: "No"
        },
        {
          id: "unreimbursedPartnershipExpense",
          fieldName: "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowA[0].f2_15[0]",
          fieldHighlights: [
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowA[0].f2_15[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowB[0].f2_20[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowC[0].f2_25[0]",
            "topmostSubform[0].Page2[0].Table_Line28g-k[0].RowD[0].f2_30[0]"
          ],
          label: "Passive Loss Allowed for UPE or Unreimbursed Partnership Expense",
          line: "Line 28(g)",
          isCurrency: true,
          isPositive: true,
          hasHelp: true
        }
      ]
    }
  ]
}

