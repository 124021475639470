import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1120sm3,
  waivable: true,
  pdf: `static/forms/${ty}/${ty}_1120sm3.pdf`,
  route: `/se-eval/std-tax-form-1120sm3/${Enums.SeTaxFormType.tf1120sm3}/${ty}`,
  fields: [
    {
      id: "mealsAndEntertainment",
      fieldName: "topmostSubform[0].Page3[0].Part3Table[0].Row8[0].f3_30[0]",
      label: "Meals and entertainment. Permanent difference",
      line: "Line 8(c)",
      isCurrency: true,
      isPositive: true,
      hasHelp: true,
      pageHeader: "Find Part III of Schedule M-3 (page 3)"
    }
  ]
}

