<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1040 Sch 1 ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

              <template v-if="row.line && !isHidden[index]">
                <label :for="row.id" class="se-std-field-group-label">
                    <span>{{row.line}}</span>
                  </label>
                  <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                    <i class="fa fa-info-circle"></i>
                  </a>
              </template>

              <template v-if="row.id === 'carryoverLoss' && !isHidden[index]">
                <p class="mt-1">
                  {{row.description}}
                </p>
              </template>

              <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">

                  <template v-if="row.id === 'carryoverLoss'">
                    <template v-if="!isHidden[index]">
                      <radio :label="false"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1">
                        <span class="se-std-label">Enter {{row.name}} for this business</span>
                      </radio>
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                             type="text"
                             v-mask="row.mask"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value" />
                      <radio :label="true"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">There is no {{row.name}} amount for this business</span>
                      </radio>
                    </template>
                  </template>

                  <template v-else>
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no amount on this line</span>
                    </radio>
                    <div class="bp-help is-error" v-if="$v.tableData.$each[index].$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="row.id === 'businessIncomeLoss' && !$v.tableData.$each[index].checkRequiredEx">{{appName}} expects an amount on Line 3. Verify the business structure and tax years have been correctly identified.</template>
                      <template v-else-if="row.id === 'rentalReRoyaltiesPartnerships' && !$v.tableData.$each[index].checkRequiredEx">{{appName}} expects an amount on Line 5. Verify the income type, business structure and tax years have been correctly identified.</template>
                      <template v-else-if="!$v.tableData.$each[index].checkHasAnswer">Please provide an answer</template>
                    </div>
                  </template>

                </div>

            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>

      </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information about Additional Income and Adjustments to Income from the borrower’s personal federal income tax returns.
      </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.businessIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
        Q. I’m not used to considering this information when I evaluate income from self-employment, business ownership or rental property. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
		A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating income from self-employment, business ownership or rental property. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
        Q. Why is this information important to {{ appName }}?<br>
        A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
        <p>
        Q. There is no amount on this line. Is that common?<br>
        A. If the business is a Sole Proprietorship and there is no amount on this line:<br>
        <ul>
          <li>
          the selection on the Business Structure screen may be inaccurate,</li>
          <li>
          the year or from selected on the Select Tax Forms screen may be inaccurate,</li>
          <li>
          the correct borrower’s tax returns may not have been selected for this evaluation.
          Double-check that the name and social security number on the tax returns correspond
          to a self-employed borrower on the loan application, or</li>
          <li>
          the tax returns may not have been prepared correctly.</li>
        </ul>

        <p>
        Q. I think the tax return may have an issue. The business is a Sole Proprietorship
          and there is no amount on this line. What should I provide to {{ appName }}? Do I need to notify
          the borrower of this potential filing error?<br>
        A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax returns.
          {{ appName }} will use other available information to help determine the borrower’s monthly qualifying income.
          You are not required to notify the borrower of any potential errors as part of using {{ appName }}.
          You should ensure the tax returns are complete, including all schedules and attachments.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalReRoyaltiesPartnerships">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate income from self-employment, business ownership or rental property. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
		 A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating income from self-employment, business ownership or rental property. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>

        <p>
        Q. Why is this information important to {{ appName }}?<br>
        A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>

        <p>         
        Q. There is no amount on this line. Is that common?<br>
        A. For a rental property or if the business is a Partnership or S-Corporation and there is no amount on this line:
        <ul>
          <li>the selection on the Business Structure screen may be inaccurate,
          </li>
          <li>the year or form selected on the Select Tax Forms screen may be inaccurate,
          </li>
          <li> the correct borrower’s tax returns may not have been selected for this evaluation. Double-check that the name and social
            security number on the tax returns correspond to a self-employed borrower on the loan application, or
            </li>
          <li>the tax returns may not have been prepared correctly.
          </li>
        </ul>

        <p>
        Q. I think the tax return may have an issue. This evaluation is for a rental property or the business is a Partnership or S-Corporation 
          and there is no amount on this line. What should I provide to {{ appName }}? Do I need to notify the borrower of this potential error? <br>
        A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax returns.
          {{ appName }} will use other available information to help determine the borrower’s monthly qualifying income.
          You are not required to notify the borrower of any potential errors as part of using {{ appName }}.
          You should ensure the tax returns are complete, including all schedules and attachments.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.carryoverLoss">
    <div class="se-field-help">
      <p>
        The Carryover Losses (Net operating losses) reported on Schedule 1 are a total of the losses for all the borrower’s businesses and rental properties. 
        Because {{ appName }} evaluates one business at a time, you need to determine how much (if any) of the total amount reported on Schedule 1 is associated 
        to the business or rental properties you want {{ appName }} to evaluate.
      </p>
      <b>FAQs:</b>

      <p>
       Q. The borrower owns more than one business or rental property. How do I determine what Carryover Loss amount is associated to the 
        business or rental property I want {{ appName }} to evaluate?<br>
	   A. There may be an attachment in the tax return that lists the losses for each business or rental property. 
        You may ask the borrower or tax preparer for this information. Because Carryover Losses are added back to the monthly qualifying income, 
        the amount entered should be supported by documentation in the loan file.
      </p>

    </div>
  </se-help>

    <se-help :visible.sync="showFieldHelp.netOperatingLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I’m not used to considering this information when I evaluate income from self-employment, business ownership or rental property. 
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
		 A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. 
          {{ appName }} may collect information not traditionally used in evaluating income from self-employment, business ownership or rental property. 
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import Enums from 'src/model/enums'
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
    }
  },
  validations: {
    tableData: {
      $each: {
        checkRequired(row) {
          return this.checkRequired(row)
        },
        checkRequiredEx(row) {
          if (row.id === 'businessIncomeLoss' && this.seRequest.extraData.businessStructureProvided === Enums.SeBusinessStructureType.soleProprietorship.id) {
            return row.value && !row.isEmpty
          } else if (row.id === 'rentalReRoyaltiesPartnerships' && this.getPY1 === this.taxFormYear
              && (this.seRequest.extraData.businessStructureProvided === Enums.SeBusinessStructureType.partnership.id
                || this.seRequest.extraData.businessStructureProvided === Enums.SeBusinessStructureType.sCorp.id
                || this.isRental)) {
            return row.value && !row.isEmpty
          }
          return true
        }
      }
    },
    allAnswered: {
      answered: (v) => v
    }
  },
  computed: {
    isSkipped() {
      return this.tableData.map(f => {

      if ("carryoverLoss" === f.id ) {
        return (this.getValueAsNumber("otherIncomeLoss") === 0) && (this.getValueAsNumber("netOperatingLoss") === 0)
      }
      return false
      })
    },
    isHidden() {
      //certain fields should be hidden when they are skipped
      return this.tableData.map((f,index) => {
        if (["carryoverLoss"].includes(f.id)) {
          return this.isSkipped[index]
        }
        return false
      })
    },

  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
