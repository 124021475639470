import {mapGetters, mapState} from "vuex";
import Enums from "../model/enums";
import {IconFlag, IconBook, IconArrowRight} from 'src/components/index'

export const FindingsMixin = {
  components: {
    IconFlag, IconBook, IconArrowRight
  },
  data() {
    return {
      showFieldHelp: {},
      showHelpMessageMemo: false,
      showHelpMessageMemoText: '',
      showHelpMessageLearnMoreUrl: null
    }
  },
  created() {
    this.Enums = Enums
  },
  computed: {

    ...mapState({
      seRequest: state => state.se.request,
      seResponse: state => state.se.response,
      seConf: state => state.se.conf,
      seVisitedFindings: state => state.se.visitedFindings
    }),
    ...mapGetters([
      'getNumberOfSelectedTaxFormYears',
      'getBusinessStructureEvaluatedAndDerived',
      'getPreparedRequest',
      'getPY1',
      'getPY2',
      'getHintAmount',
      'isRentalScheduleE'
    ]),
  },

  methods: {
    handleFieldHelp(fieldId) {
      this.showFieldHelp[fieldId] = true
    },
    displayMessageMemo(msgId, learnMoreUrl) {
      let msgText = this.seResponse.messages?.find(msg => msg.id === msgId)?.msg ?? ''
      if (msgText) {
        this.showHelpMessageMemoText = msgText
        this.showHelpMessageMemo = true
        this.showHelpMessageLearnMoreUrl = null
      }
      if (learnMoreUrl) {
        this.showHelpMessageLearnMoreUrl = learnMoreUrl
      }
    },
    findFirstMessageId(memos) {
      if (!memos || memos.length === 0) return null
      return this.seResponse.messages?.find(msg => memos.includes(msg.rule))?.id ?? null
    },
    editField(route) {
      this.$router.push(route);
    },
    getTaxFormFieldLocation(taxYear, retType, taxFormType, fields, fieldId) {
      let taxForm = this.seConf.taxYears.find(ty => ty.year === taxYear).forms
        .find(rtn => rtn.id === retType).forms
        .find(f => f.id === taxFormType)

      let fieldLocation = ''
      if (taxForm.parts) {
        fieldLocation = taxForm.parts.find(p => p.fields.some(ff => ff.id === fieldId)).route
      } else {
        fieldLocation = taxForm.route
      }

      if (fields) {
        return fieldLocation + "?loc=" + fields.find(f => f.id === fieldId)?.id;
      } else {
        return undefined
      }
    },
    getTaxFormValueAsNumber(fields, fieldId) {
      let value = Number(this.getTaxFormValue(fields, fieldId))
      return Number.isNaN(value) ? 0.0 : value
    },
    getTaxFormValue(fields, fieldId, defValue = 0.0) {
      let value = defValue;
      if (fields) {
        value = fields.find(f => f.id === fieldId)?.value;
        if (value === undefined || value.isEmpty || value === "") {
          value = defValue;
        }
      }
      return value;
    },
    getTaxFormFields(taxYear, taxFormId) {
      let data = this.seRequest.taxData.find(f => f.year === taxYear && f.formId === taxFormId && !f.skipped);
      if (data && data.fields) {
        return data.fields;
      }
      return undefined;
    },
    getMortgagesNotesPayableLessThanOneYearEndOfYear(fields) {
      let mortgagesNotesPayableLessThanOneYearEndOfYear = 0.0
      let rollType = this.getTaxFormValue(fields, 'mortgagePayableLess1YrRolloverType');
      if (rollType === Enums.MortgagePayableLess1YrRolloverType.all) {
        mortgagesNotesPayableLessThanOneYearEndOfYear = this.getTaxFormValue(fields, 'mortgagesNotesPayableLess1YrEndOfTaxYear')
      } else if (rollType === Enums.MortgagePayableLess1YrRolloverType.some) {
        mortgagesNotesPayableLessThanOneYearEndOfYear = this.getTaxFormValue(fields, 'mortgagesNotesPayableLess1YrEndOfTaxYear')
      } else {
        mortgagesNotesPayableLessThanOneYearEndOfYear = this.getTaxFormValue(fields, 'mortgagesNotesPayableLess1YrEndOfTaxYear')
      }
      return mortgagesNotesPayableLessThanOneYearEndOfYear;
    }

  }
}
