<template>
  <div>
    <div class="container">

    <h1 class="se-bp-title">
      Reference Name (Optional)
      <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showHelp = true">
        <i class="fa fa-info-circle"></i>
      </a>
    </h1>
    <h4>You may optionally create a reference name for the data from this employment or rental property. Do not provide personally identifiable information, such as individual names or addresses.</h4>

    <form class="form-horizontal" autocomplete="off">
      <div class="row">
        <div class="col-6">
          <fg-input label=""
                    type="text"
                    :readonly="isLocked"
                    placeholder="e.g. Business Name"
                    v-model="refName">
          </fg-input>
        </div>
      </div>
    </form>

    <se-help :visible.sync="showHelp" title="Learn More">
      <p>
        Use this screen to create a reference name for the data from this income source. Creating a reference name may help you identify and track 
        the correct income data when a borrower has more than one income source. If you assign a reference name, it will appear on each {{ appName }} 
        screen and will be printed on the {{ appName }} Findings Report. You may use a business name, a street address, or you may use a generic name 
        such as “Borrower 1/Source 1.” You should ensure the reference name you select does not contain any non-public personally identifying information 
        such as the borrower’s full Social Security Number or borrower’s full name.
      </p>
      <b>FAQs:</b>
      <p>
        Q. Will I need to complete a separate {{ appName }} evaluation for each business or rental property?<br>
		A. Yes. If the borrower has multiple businesses or rental properties, you will need to complete a separate {{ appName }} evaluation for each.<br>
      </p>
      <p>
        For example, if the borrower is a partner in a tree growing business, is also a partner
        in a landscape design business, and files separate business tax returns for each
        business, then you will need two separate {{ appName }} evaluations; one for each business.
      </p>


      <p>
        Q. The tax returns indicate the borrower has an ownership interest in multiple separate businesses, but the loan application appears to have merged 
        some of the businesses together or they are reported as “Other Income.” What Reference Name should I provide?<br>
		A. Using a Reference Name is optional. {{ appName }} does not attempt to match incomes to another source, such as DU, using the Reference Name. 
        The loan application must separately identify each employment with accurate employment data in Section 1b/1c of the Uniform Residential Loan Application. 
        If the borrower has multiple employments, you will need a separate {{ appName }} evaluation for each business where the borrower has an ownership interest.
      </p>

     <p>
       Q. The tax returns indicate the borrower has multiple rental properties, but the loan application appears to have merged some of the rental properties together. 
       What Reference Name should I provide?<br>
	   A. Using a Reference Name is optional. {{ appName }}  does not attempt to match incomes to another source, such as DU, using the Reference Name. 
       The loan application must separately identify each rental property with accurate data in Section 3 of the Uniform Residential Loan Application. 
       If the borrower has multiple rental properties, you will need a separate {{ appName }}  evaluation for each property. 
       If the tax returns do not contain separate data for each rental property, {{ appName }}  cannot be used for evaluation.
       </p>
      
      <p>
        Q. There are two borrowers on the loan. The borrowers are each 50% owners of the Partnership,
        S-Corporation, Corporation, or LLC. Will I need to complete a separate {{ appName }}
        evaluation for each borrower?<br>
        A. Yes. Completing separate income evaluations is <b>required</b> for each borrower.
      </p>

    </se-help>

    <se-buttons
      :display-save="false"
      :display-submit="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>
  </div>
  </div>

</template>
<script>
import {Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import SeButtons from './SeButtons.vue'
import {mapGetters, mapState} from 'vuex'
import {SET_REQUEST_DATA} from 'src/store/actions/se'
import SeHelp from './SeHelp.vue'
import appNameMixin from '../../../mixins/AppNameMixin'

export default {
    mixins:[appNameMixin],
  components: {
    Radio,
    LSwitch,
    SeButtons,
    SeHelp
  },
  data() {
    return {
      showHelp: false
    }
  },
  computed: {
    refName: {
      get () {  return this.seRequest.refName },
      set (refName) { this.$store.commit(SET_REQUEST_DATA, {refName})  }
    },
    ...mapState({
      seRequest: state => state.se.request
    }),
    ...mapGetters([
      'isLocked',
      'isDisplayBusScreens'
    ])
  },
  methods: {
    next() {
      if (!this.refName || this.refName === '') {
        this.refName = 'Not Provided'
      }
      this.$router.push( this.isDisplayBusScreens ? 'business-length' : 'du-evaluation')
    },
    previous() {
      this.$router.push('income-type')
    }

  }
}
</script>
<style>

</style>
