
const SeStatus = {
  success: 'success',
  error: 'error',
  loading: 'loading'
}

const SeRequestType = {
  rtImport: 'Import',
  rtNewData: 'NewEvaluation',
  rtExport: 'Export',
  rtCopy: 'Copy',
  rtUpdate: 'Update',
  rtViewFindings: 'ViewFindings'
}

const SeEvaluationDataSourceType = {
  dsClone: 'Clone',
  dsImport: 'Import',
  dsManual: 'ManualEntry',
}

const SeEmploymentType = {
  primary: 'Primary',
  secondary: 'Secondary'
}

const SeEmploymentClassificationType = {
  borrowerPrimaryIncomeSource: 'borrowerPrimaryIncomeSource',
  borrowerSecondaryIncomeSourceWithPrimarySE: 'borrowerSecondaryIncomeSourceWithPrimarySE',
  borrowerSecondaryIncomeSourceWithPrimaryNotSE: 'borrowerSecondaryIncomeSourceWithPrimaryNotSE'
}

const SeUserType = {
  utBorrower: 'Borrower',
  utBrokerLender: 'BrokerLender',
  utOther: 'Other',
  utDeclined: 'Declined'
}

const SeYesNoType = {
  Yes: 'Yes',
  No: 'No'
}

const SeIncomeTypeType = {
  selfEmployed: 'selfEmployed',
  rental: 'rental'
}

const SeRentalIncomeIrsFormType = {
  businessTaxReturns: 'businessTaxReturns',
  scheduleEPart1: 'scheduleEPart1'
}

const SeTaxFormType = {
  tf1040: '1040',
  tf1040sr: '1040SR',
  tf1040nr: '1040NR',
  tf1065: '1065',
  tf1120: '1120',
  tf1120s: '1120S',
  tf1040c: '1040_SCH_C',
  tf1040s1: '1040_SCH_1',
  tf1040se: '1040_SCH_E',
  tf4684: '4684',
  tf4562: '4562',
  tf8825: '8825',
  tf1065sk1: '1065_SCH_K1',
  tf1065sm3: '1065_SCH_M3',
  tf1120ssk: '1120S_SCH_K1',
  tf1120sm3: '1120S_SCH_M3',
  tf1120m3: '1120_SCH_M3',
  tf1125e: '1125E',
  tfw2: 'W2',
  tfLossesExpenses: 'LOSSES_EXPENSES',
}

const SeBusinessStructureType= {
  rental: { id: "rental", name: "Rental", derivedName: "Rental Income" },
  soleProprietorship: { id: "soleProprietorship", name: "Sole Proprietorship (Schedule C)", derivedName: "Sole Proprietorship" },
  sCorp: { id: "sCorp", name: "S Corporation", derivedName: "S-Corporation" },
  partnership: { id: "partnership", name: "Partnership", derivedName: "Partnership" },
  corp: { id: "corp", name: "Corporation", derivedName: "Corporation" },
  llc: { id: "llc", name: "Limited Liability Company (LLC)", derivedName: "LLC" },
  unknown: { id: "unknown", name: "I don't know", derivedName: "Unknown" }
}

const EmploymentOwnershipInterestProvidedType = {
  gte25: 'greaterThanOrEqualTo25Percent',
  lt25: 'lessThan25Percent'
}

const SeEvalRequestYearsType = {
  oneYr: "OneYr",
  twoYrs: "TwoYrs",
  oneYrPersonal: "OneYrPersonal",
  twoYrsPersonal: "TwoYrsPersonal",
  oneYrPersonalOneYrBusiness: "OneYrPersonalOneYrBusiness",
  twoYrsPersonalTwoYrsBusiness: "TwoYrsPersonalTwoYrsBusiness",
  perDuRequirement: "PerDuRequirement",
  unknown: "Unknown"
}

const SeRentalPropertyType= {
  singleFamilyResidence: {id: "singleFamilyResidence", label: "1  Single Family Residence"},
  multiFamilyResidence: {id: "multiFamilyResidence", label: "2  Multi-Family Residence"},
  vacationShortTerm: {id: "vacationShortTerm", label: "3  Vacation/Short-Term Rental"},
  commercial: {id: "commercial", label: "4  Commercial"},
  land: {id: "land", label: "5  Land"},
  selfRental: {id: "selfRental", label: "7  Self-Rental"},
  other: {id: "other", label: "8  Other"}
}

const SeFairRentalDaysLessThan365DetailType= {
  acquiredDuringTaxYear: "acquiredDuringTaxYear",
  rehabilitationRenovation: "rehabilitationRenovation",
  unknown: "unknown"
}

const SeRentalMonthlyQualifiedIncomeZeroMemos = [
  'RENTAL-INCOME-NOT-PERMITTED-URLA-ENTRY',
  'RENTAL-INCOME-ZERO-POSITIVE-CASH-FLOW-URLA-ENTRY'
]

const SeAdjustedRentalIncomeMemos = [
  'RENTAL-HOUSING-EXPENSE-NEGATIVE-OR-MISSING',
  'RENTS-RECEIVED-NEGATIVE-OR MISSING-SCHE',
  'ACCESSORY-BOARDER-GREATER-THAN-RENT-CURRENT-YEAR',
  'DEDUCTIBLE-MORTGAGE-INTEREST-GREATER-THAN-OTHER-INTEREST-CURRENT-YEAR'
]

const SeMonthlyQualifiedIncomeZeroMemos = [
  'SCHEDULEC-BUSINESS-AGE-LT12-MONTHS-CURRENT-YEAR',
  'BUSINESS-STRUCTURE-CHANGE',
  'EMPLOYMENT-LESS-THAN-24-MONTHS-INCONSISTENT-HISTORY',
  'SCORPORATION-BUSINESS-AGE-LT12-MONTHS-CURRENT-YEAR',
  'EMPLOYMENT-LESS-THAN-12-MONTHS',
  'FINAL-RETURN',
  'FISCAL-YEAR',
  'BUSINESS-OWNERSHIP-PERCENT-NULL-PRIOR-YEAR',
  'K1-NET-RENTAL-INCOME',
  'K1-NAME-TRUST-BORROWER-NOT-GRANTOR',
  'PARTNERSHIP-BUSINESS-AGE-LT12-MONTHS-CURRENT-YEAR',
  'K1-PARTNERSHIP-PERCENT-LESS-THAN-25-CURRENT-YEAR',
  'BUSINESS-OWNERSHIP-PERCENT-NULL-PRIOR-YEAR',
  'CORPORATION-BUSINESS-AGE-LT12-MONTHS-CURRENT-YEAR',
  'COMMON-STOCK-PERCENT-LESS-THAN-25-CURRENT-YEAR',
  'OWNERSHIP-SHARE-BUSINESS-RETURNS-INCONSISTENT',
  'ONE-YEAR-RETURNS-LESS-THAN-25-PERCENT-OWNERSHIP',
  'EMPLOYMENT-LESS-THAN-24-MONTHS-LESS-THAN-25-PCT-OWNERSHIP',
  'K1-OWNERSHIP-PERCENT-MISSING'
]

const SePYQualifiedIncomeZeroMemos = [
  'PRIOR-YEAR-INCOME-NOT-INCLUDED-REASON-DECLINING-INCOME',
  'K1-SHAREHOLDER-PERCENT-LESS-THAN-25-PRIOR-YEAR',
  'K1-PARTNERSHIP-PERCENT-LESS-THAN-25-PRIOR-YEAR',
  'COMMON-STOCK-PERCENT-LESS-THAN-25-PRIOR-YEAR'
]

const SeFindingsSummaryRentalSchELineType= {
  fairRentalDaysCount: {
    id: "fairRentalDaysCount", name: "Fair Rental Days",
    memoCy: ['FAIR-RENTAL-DAYS-ALTERNATIVE-SOURCE', 'FAIR-RENTAL-DAYS-DEFAULT', 'FAIR-RENTAL-DAYS-LT365-UNKNOWN-REASON', 'FAIR-RENTAL-DAYS-RENO-NOT-SUPPORTED-BY-REPAIRS']
  },
  rentsReceived: { id: "rentsReceived", name: "Rents Received"},
  rentalPropertyAccessoryBoarderIncome: { id: "rentalPropertyAccessoryBoarderIncome", name: "Accessory Unit or Boarder Income",
    memoCy: ['RENTAL-PROPERTY-ACCESSORY-UNIT-BOARDER-INCOME']
  },
  rentalPropertyTotalExpenses: { id: "rentalPropertyTotalExpenses", name: "Total Expenses"},
  rentalPropertyInsurance: { id: "rentalPropertyInsurance", name: "Insurance"},
  rentalPropertyMortgageInterestPaidToBanks: { id: "rentalPropertyMortgageInterestPaidToBanks", name: "Mortgage Interest Paid to Banks"},
  rentalPropertyOtherDeductibleMortgageInterest: { id: "rentalPropertyOtherDeductibleMortgageInterest", name: "Other Deductible Mortgage Interest"},
  rentalPropertyTax: { id: "rentalPropertyTax", name: "Taxes"},
  rentalPropertyHomeownersAssociationDues: { id: "rentalPropertyHomeownersAssociationDues", name: "Homeowners Association Dues"},
  rentalPropertyIndividualPropertyDepreciation: { id: "rentalPropertyIndividualPropertyDepreciation", name: "Depreciation/Depletion"},
  casualtyTheftLoss: { id: "casualtyTheftLoss", name: "Casualty/theft loss"},
  extraordinaryOneTimeExpenses: { id: "extraordinaryOneTimeExpenses", name: "Extraordinary one-time expenses"}
}

const SeFindingsSummaryLineType= {
  netProfitLoss: { id: "netProfitLoss", name: "Net profit (or loss)"},
  otherIncomeLoss: { id: "otherIncomeLoss", name: "Other Income",
    memoCy:['OTHER-INCOME-SCHEDULEC-ZERO', 'OTHER-INCOME-SCHEDULEC-NONRECURRING'],
    memoPy:['OTHER-INCOME-SCHEDULEC-ZERO', 'OTHER-INCOME-SCHEDULEC-NONRECURRING']
  },
  depletion: { id: "depletion", name: "Depletion"},
  depreciation: { id: "depreciation", name: "Depreciation (standard)"},
  vehicleDepreciation: { id: "vehicleDepreciation", name: "Vehicle Depreciation (mileage method)",
    memoCy:['MILEAGE-DEPRECIATION-SCHEDULEC-MISMATCH-CURRENT-YEAR', 'MILEAGE-DEPRECIATION-INCLUDED-IN-SCHEDULEC-CURRENT-YEAR'],
    memoPy:['MILEAGE-DEPRECIATION-SCHEDULEC-MISMATCH-PRIOR-YEAR', 'MILEAGE-DEPRECIATION-INCLUDED-IN-SCHEDULEC-PRIOR-YEAR']
  },
  deductibleMeals: { id: "deductibleMeals", name: "Deductible meals"},
  nonDeductibleMeals: { id: "nonDeductibleMeals", name: "Nondeductible meals",
    memoCy:['SCHEDULEC-NONDEDUCTIBLE-MEALS-DOCUMENTATION', 'SCHEDULEC-DEDUCTIBLE-MEALS-MISSING', 'SCHEDULEC-NONDEDUCTIBLE-MEALS-NEGATIVE', 'SCHEDULEC-DEDUCTIBLE-MEALS-INCONSISTENT', 'SCHEDULEC-NONDEDUCTIBLE-MEALS-EXCEEDS-DEDUCTIBLE-CURRENT-YEAR'],
    memoPy:['SCHEDULEC-NONDEDUCTIBLE-MEALS-DOCUMENTATION', 'SCHEDULEC-DEDUCTIBLE-MEALS-MISSING', 'SCHEDULEC-NONDEDUCTIBLE-MEALS-NEGATIVE', 'SCHEDULEC-DEDUCTIBLE-MEALS-INCONSISTENT', 'SCHEDULEC-NONDEDUCTIBLE-MEALS-EXCEEDS-DEDUCTIBLE-PRIOR-YEAR']
  },
  businessUseHome: { id: "businessUseHome", name: "Business use of home"},
  amortization: { id: "amortization", name: "Amortization (Schedule C)"},
  amortization4562: { id: "amortization4562", name: "Amortization (4562)"},
  casualtyTheftLoss: { id: "casualtyTheftLoss", name: "Casualty/theft loss"},
  extraordinaryOneTimeExpenses: { id: "extraordinaryOneTimeExpenses", name: "Extraordinary one-time expenses"},
  w2MedicareWages: { id: "w2MedicareWages", name: "W-2 Medicare wages"},
}

const SeFindingsSummarySCorpLineType = {
  ordinaryBusinessIncomeLoss: { id: "ordinaryBusinessIncomeLoss", name: "Ordinary business income (loss)"},
  netRentalRealEstateIncomeLoss: { id: "netRentalRealEstateIncomeLoss", name: "Net rental real estate income (loss)"},
  otherNetRentalIncomeLoss: { id: "otherNetRentalIncomeLoss", name: "Other net rental income (loss)"},
  k1Income: { id: "k1Income", name: "K-1 Income"},
  k1Distribution: { id: "k1Distribution", fieldId: "itemsAffectingShareholderBasisCodeDAmount", name: "K-1 Distributions"},
  businessIncomeTitle: { id: "businessIncomeTitle", name: "Business Income from Form 1120S", hideLt25: true},
  netGainLoss4797: { id: "netGainLoss4797", name: "Form 4797 gain (loss)",
    memoCy:['4797-GAIN-LOSS-SCORPORATION-ZERO', '4797-GAIN-LOSS-SCORPORATION-NONRECURRING'],
    memoPy:['4797-GAIN-LOSS-SCORPORATION-ZERO', '4797-GAIN-LOSS-SCORPORATION-NONRECURRING'],
    hideLt25: true
  },
  otherIncomeLoss: { id: "otherIncomeLoss", fieldId: "otherIncomeLoss", name: "Other income (loss)",
    memoCy:['OTHER-INCOME-SCORPORATION-ZERO', 'OTHER-INCOME-SCORPORATION-NONRECURRING'],
    memoPy:['OTHER-INCOME-SCORPORATION-ZERO', 'OTHER-INCOME-SCORPORATION-NONRECURRING'],
    hideLt25: true
  },
  depreciation: { id: "depreciation", name: "Depreciation (1120S)", hideLt25: true},
  depreciation8825: { id: "depreciation8825", fieldId: "depreciation", name: "Depreciation (8825)", hideLt25: true},
  depletion: { id: "depletion", name: "Depletion", hideLt25: true},
  amortization: { id: "amortization", name: "Amortization (1120S)", hideLt25: true},
  amortization4562: { id: "amortization4562", fieldId: "amortization4562", name: "Amortization (4562)", hideLt25: true},
  casualtyTheftLoss: { id: "casualtyTheftLoss", name: "Casualty/theft loss", hideLt25: true},
  extraordinaryOneTimeExpenses: { id: "extraordinaryOneTimeExpenses", name: "Extraordinary one-time expenses", hideLt25: true},
  mortgagesNotesPayableLessThanOneYearEndOfYear: { id: "mortgagesNotesPayableLessThanOneYearEndOfYear", fieldId: "mortgagesNotesPayableLess1YrEndOfTaxYear", name: "Mortgages, notes or bonds payable in less than 1 year",
    memoCy:['MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-ALL-EXCLUDED-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-NONE-EXCLUDED-CURRENT-YEAR',
      'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-SOME-EXCLUDED-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-EXCLUDED-EXCEEDS-AMOUNT-SCORPORATION-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-SUFFICIENT-CASH'],
    memoPy:['MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-ALL-EXCLUDED-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-NONE-EXCLUDED-PRIOR-YEAR',
      'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-SOME-EXCLUDED-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-EXCLUDED-EXCEEDS-AMOUNT-SCORPORATION-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-SUFFICIENT-CASH'],
    hideLt25: true
  },
  travelEntertainment: { id: "travelEntertainment", name: "Travel and entertainment (M-1)", changeOverYearFromTaxReturn: true, hideLt25: true},
  deductibleMeals: { id: "deductibleMeals", fieldId: "mealsAndEntertainment", name: "Meals and entertainment (M-3)", changeOverYearFromTaxReturn: true, hideLt25: true},
  totalBusinessIncome: { id: "totalBusinessIncome", name: "Subtotal Business Income", hideLt25: true},
  ownershipPercent: { id: "ownershipPercent", fieldId: "cyAllocationPercentage", name: "Borrower's Ownership Percent", hideLt25: true},
  totalBusinessIncomeShare: { id: "totalBusinessIncomeShare", name: "Borrower's Share of Business Income", hideLt25: true},
  w2MedicareWages: { id: "w2MedicareWages", name: "W-2 Medicare wages"},
  compensationOfOfficers: { id: "compensationOfOfficers", name: "Compensation of Officers",
    memoCy:['COMPENSATION-OF-OFFICERS-NOT-ON-1040'],
    memoPy:['COMPENSATION-OF-OFFICERS-NOT-ON-1040']
  },
  distributionGuaranteedPaymentOrK1Income: { id: "distributionGuaranteedPaymentOrK1Income", name: "K-1 Income or Distributions (from above)"},
}

const SeFindingsSummaryPartnershipLineType = {
  ordinaryBusinessIncomeLoss: { id: "ordinaryBusinessIncomeLoss", name: "Ordinary business income (loss)"},
  netRentalRealEstateIncomeLoss: { id: "netRentalRealEstateIncomeLoss", name: "Net rental real estate income (loss)"},
  otherNetRentalIncomeLoss: { id: "otherNetRentalIncomeLoss", name: "Other net rental income (loss)"},
  k1Income: { id: "k1Income", name: "K-1 Income"},
  k1Distribution: { id: "k1Distribution", fieldId: "distributionCodeAAmount", name: "K-1 Distributions"},
  guaranteedPaymentsForServices: { id: "guaranteedPaymentsForServices", name: "Guaranteed Payments to Partners For Services"},
  guaranteedPaymentsForCapital: { id: "guaranteedPaymentsForCapital", name: "Guaranteed Payments to Partners For Capital"},
  totalGuaranteedPayments1: { id: "totalGuaranteedPayments1", fieldId: "totalCalculatedGuaranteedPayments", name: "Total Guaranteed Payments to Partners", responseFieldId: "totalCalculatedGuaranteedPayments"},
  businessIncomeTitle: { id: "businessIncomeTitle", name: "Business Income from Form 1065", hideLt25: true},
  ordinaryIncomeLossFromOtherPartnershipsEstates: { id: "ordinaryIncomeLossFromOtherPartnershipsEstates", name: "Ordinary Income from Other Partnerships Estates and Trusts",
    memoCy:['OTHER-PARTNERSHIP-ESTATE-TRUST-ZERO', 'OTHER-PARTNERSHIP-ESTATE-TRUST-NONRECURRING'],
    memoPy:['OTHER-PARTNERSHIP-ESTATE-TRUST-ZERO', 'OTHER-PARTNERSHIP-ESTATE-TRUST-NONRECURRING'],
    hideLt25: true
  },
  netGainLoss4797: { id: "netGainLoss4797", name: "Form 4797 gain (loss)",
    memoCy:['4797-GAIN-LOSS-PARTNERSHIP-ZERO', '4797-GAIN-LOSS-PARTNERSHIP-NONRECURRING'],
    memoPy:['4797-GAIN-LOSS-PARTNERSHIP-ZERO', '4797-GAIN-LOSS-PARTNERSHIP-NONRECURRING'],
    hideLt25: true
  },
  netFarmGainLoss: { id: "netFarmGainLoss", fieldId: "netFarmProfitLoss", name: "Farm profit (loss)",
    memoCy:['FARM-PROFIT-LOSS-PARTNERSHIP-ZERO', 'FARM-PROFIT-LOSS-PARTNERSHIP-NONRECURRING'],
    memoPy:['FARM-PROFIT-LOSS-PARTNERSHIP-ZERO', 'FARM-PROFIT-LOSS-PARTNERSHIP-NONRECURRING'],
    hideLt25: true
  },
  otherIncomeLoss: { id: "otherIncomeLoss", fieldId: "otherIncomeLoss", name: "Other income (loss)",
    memoCy:['OTHER-INCOME-PARTNERSHIP-ZERO', 'OTHER-INCOME-PARTNERSHIP-NONRECURRING'],
    memoPy:['OTHER-INCOME-PARTNERSHIP-ZERO', 'OTHER-INCOME-PARTNERSHIP-NONRECURRING'],
    hideLt25: true
  },
  depreciation: { id: "depreciation", name: "Depreciation (1065)", hideLt25: true},
  depreciation8825: { id: "depreciation8825", fieldId: "depreciation", name: "Depreciation (8825)", hideLt25: true},
  depletion: { id: "depletion", name: "Depletion", hideLt25: true},
  amortization: { id: "amortization", name: "Amortization (1065)", hideLt25: true},
  amortization4562: { id: "amortization4562", fieldId: "amortization4562", name: "Amortization (4562)", hideLt25: true},
  casualtyTheftLoss: { id: "casualtyTheftLoss", name: "Casualty/theft loss", hideLt25: true},
  extraordinaryOneTimeExpenses: { id: "extraordinaryOneTimeExpenses", name: "Extraordinary one-time expenses", hideLt25: true},
  mortgagesNotesPayableLessThanOneYearEndOfYear: { id: "mortgagesNotesPayableLessThanOneYearEndOfYear", fieldId: "mortgagesNotesPayableLess1YrEndOfTaxYear", name: "Mortgages, notes or bonds payable in less than 1 year",
    memoCy:['MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-ALL-EXCLUDED-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-NONE-EXCLUDED-CURRENT-YEAR',
      'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-SOME-EXCLUDED-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-EXCLUDED-EXCEEDS-AMOUNT-PARTNERSHIP-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-SUFFICIENT-CASH'],
    memoPy:['MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-ALL-EXCLUDED-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-NONE-EXCLUDED-PRIOR-YEAR',
      'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-SOME-EXCLUDED-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-EXCLUDED-EXCEEDS-AMOUNT-PARTNERSHIP-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-SUFFICIENT-CASH'],
    hideLt25: true
  },
  travelEntertainment: { id: "travelEntertainment", name: "Travel and entertainment (M-1)", changeOverYearFromTaxReturn: true, hideLt25: true},
  deductibleMeals: { id: "deductibleMeals", fieldId: "mealsAndEntertainment", name: "Meals and entertainment (M-3)", changeOverYearFromTaxReturn: true, hideLt25: true},
  totalBusinessIncome: { id: "totalBusinessIncome", name: "Subtotal Business Income", hideLt25: true},
  ownershipPercent: { id: "ownershipPercent", fieldId: "partnerShareOfCapitalEndingPct", name: "Borrower's Ownership Percent", hideLt25: true},
  totalBusinessIncomeShare: { id: "totalBusinessIncomeShare", name: "Borrower's Share of Business Income", hideLt25: true},
  totalGuaranteedPayments2: { id: "totalGuaranteedPayments2", name: "Total Guaranteed Payments to Partners", hideLt25: true, responseFieldId: "totalCalculatedGuaranteedPayments"},
  w2MedicareWages: { id: "w2MedicareWages", name: "W-2 Medicare wages"},
  distributionGuaranteedPaymentOrK1Income: { id: "distributionGuaranteedPaymentOrK1Income", name: "K-1 Income or Distributions (from above)"},
}

const SeFindingsSummaryCorpLineType = {
  taxableIncome: { id: "taxableIncome", name: "Taxable income"},
  totalTax: { id: "totalTax", name: "Total tax"},
  capitalGainNetIncome: { id: "capitalGainNetIncome", name: "Capital gain (loss)",
    memoCy:['CAPITAL-GAIN-LOSS-CORPORATION-ZERO', 'CAPITAL-GAIN-LOSS-CORPORATION-NONRECURRING'],
    memoPy:['CAPITAL-GAIN-LOSS-CORPORATION-ZERO', 'CAPITAL-GAIN-LOSS-CORPORATION-NONRECURRING']
  },
  netGainLoss4797: { id: "netGainLoss4797", name: "Form 4797 gain (loss)",
    memoCy:['4797-GAIN-LOSS-CORPORATION-ZERO', '4797-GAIN-LOSS-CORPORATION-NONRECURRING'],
    memoPy:['4797-GAIN-LOSS-CORPORATION-ZERO', '4797-GAIN-LOSS-CORPORATION-NONRECURRING']
  },
  otherIncomeLoss: { id: "otherIncomeLoss", fieldId: "otherIncomeLoss", name: "Other income (loss)",
    memoCy:['OTHER-INCOME-CORPORATION-ZERO', 'OTHER-INCOME-CORPORATION-NONRECURRING'],
    memoPy:['OTHER-INCOME-CORPORATION-ZERO', 'OTHER-INCOME-CORPORATION-NONRECURRING']
  },
  depreciation: { id: "depreciation", name: "Depreciation (1120)"},
  depletion: { id: "depletion", name: "Depletion"},
  amortization: { id: "amortization", name: "Amortization (1120)"},
  amortization4562: { id: "amortization4562", fieldId: "amortization4562", name: "Amortization (4562)"},
  casualtyTheftLoss: { id: "casualtyTheftLoss", name: "Casualty/theft loss"},
  extraordinaryOneTimeExpenses: { id: "extraordinaryOneTimeExpenses", name: "Extraordinary one-time expenses"},
  netOperatingLossDeduction: { id: "netOperatingLossDeduction", name: "Net operating loss"},
  specialDeductions: { id: "specialDeductions", name: "Special deductions"},
  mortgagesNotesPayableLessThanOneYearEndOfYear: { id: "mortgagesNotesPayableLessThanOneYearEndOfYear", fieldId: "mortgagesNotesPayableLess1YrEndOfTaxYear", name: "Mortgages, notes or bonds payable in less than 1 year",
    memoCy:['MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-ALL-EXCLUDED-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-NONE-EXCLUDED-CURRENT-YEAR',
      'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-SOME-EXCLUDED-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-EXCLUDED-EXCEEDS-AMOUNT-CORPORATION-CURRENT-YEAR', 'MORTGAGES-NOTES-BONDS-SUFFICIENT-CASH'],
    memoPy:['MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-ALL-EXCLUDED-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-NONE-EXCLUDED-PRIOR-YEAR',
      'MORTGAGES-NOTES-BONDS-PAYABLE-LESS-THAN-ONE-YEAR-SOME-EXCLUDED-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-EXCLUDED-EXCEEDS-AMOUNT-CORPORATION-PRIOR-YEAR', 'MORTGAGES-NOTES-BONDS-SUFFICIENT-CASH']
  },
  travelEntertainment: { id: "travelEntertainment", name: "Travel and entertainment (M-1)", changeOverYearFromTaxReturn: true},
  deductibleMeals: { id: "deductibleMeals", fieldId: "mealsAndEntertainment", name: "Meals and entertainment (M-3)", changeOverYearFromTaxReturn: true},
  cashDistributions: { id: "cashDistributions", name: "Cash distributions"},
  totalBusinessIncome: { id: "totalBusinessIncome", name: "Subtotal Business Income"},
  ownershipPercent: { id: "ownershipPercent", fieldId: "cyAllocationPercentage", name: "Borrower's Ownership Percent"},
  totalBusinessIncomeShare: { id: "totalBusinessIncomeShare", name: "Borrower's Share of Business Income"},
  w2MedicareWages: { id: "w2MedicareWages", name: "W-2 Medicare wages"},
  compensationOfOfficers: { id: "compensationOfOfficers", name: "Compensation of Officers",
    memoCy:['COMPENSATION-OF-OFFICERS-NOT-ON-1040'],
    memoPy:['COMPENSATION-OF-OFFICERS-NOT-ON-1040']
  }
}

const SeFindingsCasualtyTheftLossLineType = {
  casualtyTheftNonIncomePropertyShortTermGainLossAmount: {id: "casualtyTheftNonIncomePropertyShortTermGainLossAmount", name: "Gain or loss from non-income producing property held one year or less"},
  casualtyTheftIncomePropertyShortTermLossAmount: {id: "casualtyTheftIncomePropertyShortTermLossAmount", name: "   Loss from income producing property held one year or less"},
  casualtyTheftLongTermGainAmount: {id: "casualtyTheftLongTermGainAmount", name: "Gain from property held more than one year"},
  casualtyTheftLongTermLossAmount: {id: "casualtyTheftLongTermLossAmount", name: "Loss from property held more than one year"},
}

const SeFindingsCmpIncomeAnalysisLineType = {
  grossReceiptsOrSales: {id: "grossReceiptsOrSales", name: "Gross Receipts or Sales",
    fieldLoc: {
      soleProprietorship: {formId: SeTaxFormType.tf1040c, fieldId: "grossReceiptsOrSales"},
      sCorp: {formId: SeTaxFormType.tf1120s, fieldId: "grossReceiptsOrSales"},
      partnership: {formId: SeTaxFormType.tf1065, fieldId: "grossReceiptsOrSales"},
      corp: {formId: SeTaxFormType.tf1120, fieldId: "grossReceiptsOrSales"},
    }
  },
  returnsAndAllowances: {id: "returnsAndAllowances", name: "Returns and Allowances",
    fieldLoc: {
      soleProprietorship: {formId: SeTaxFormType.tf1040c, fieldId: "returnsAndAllowances"},
      sCorp: {formId: SeTaxFormType.tf1120s, fieldId: "returnsAndAllowances"},
      partnership: {formId: SeTaxFormType.tf1065, fieldId: "returnsAndAllowances"},
      corp: {formId: SeTaxFormType.tf1120, fieldId: "returnsAndAllowances"}
    }
  },
  grossIncome: {id: "grossIncome", name: "Gross Income"},
  costOfGoodsSold: {id: "costOfGoodsSold", name: "Cost of Goods Sold",
    fieldLoc: {
      soleProprietorship: {formId: SeTaxFormType.tf1040c, fieldId: "costOfGoodsSold"},
      sCorp: {formId: SeTaxFormType.tf1120s, fieldId: "costOfGoodsSold"},
      partnership: {formId: SeTaxFormType.tf1065, fieldId: "costOfGoodsSold"},
      corp: {formId: SeTaxFormType.tf1120, fieldId: "costOfGoodsSold"}
    }
  },
  totalDeductions: {id: "totalDeductions", name: "Total Deductions",
    fieldLoc: {
      soleProprietorship: {formId: SeTaxFormType.tf1040c, fieldId: "totalExpenses"},
      sCorp: {formId: SeTaxFormType.tf1120s, fieldId: "totalDeductions"},
      partnership: {formId: SeTaxFormType.tf1065, fieldId: "totalDeductions"},
      corp: {formId: SeTaxFormType.tf1120, fieldId: "totalDeductions"}
    }
  },
  expenses: {id: "expenses", name: "Expenses"},
  expenseIncomePercent: {id: "expenseIncomePercent", name: "Expenses / Gross Income"},
  taxableIncome: {id: "taxableIncome", name: "Taxable Income",
    fieldLoc: {
      soleProprietorship: {formId: SeTaxFormType.tf1040c, fieldId: "netProfitLoss"},
      sCorp: {formId: SeTaxFormType.tf1120s, fieldId: "ordinaryBusinessIncomeLoss"},
      partnership: {formId: SeTaxFormType.tf1065, fieldId: "ordinaryBusinessIncomeLoss"},
      corp: {formId: SeTaxFormType.tf1120, fieldId: "taxableIncome"}
    }
  },
  taxableIncomePercent: {id: "taxableIncomePercent", name: "Taxable Income / Gross Income"}
}

const MortgagePayableLess1YrRolloverType = {
  all: "All",
  some: "Some",
  none: "None"
}

const DuDocumentationLevelType = {
  twoYearsPersonalAndTwoYearsBusiness: "306",
  twoYearsPersonalAndBusinessAnd2ndJob: "308"
}

const SeScheduleEPartnershipSCorpType = {
  s: "S",
  p: "P"
}

const SeAnalysisResultType = {
  needsReview: "Needs Review",
  satisfactory: "Satisfactory"
}

const SePartnerType = {
  generalPartner: "General Partner or LLC member-manager",
  limitedPartner: "Limited Partner or other LLC member",
  none: "There is no selection on this line"
}


export default {
  SeStatus, SeRequestType, SeUserType, SeYesNoType, SeIncomeTypeType, SeEmploymentType, SeEmploymentClassificationType,
  SeTaxFormType, SeBusinessStructureType, SeEvalRequestYearsType, SeFindingsSummaryLineType, SeFindingsSummaryRentalSchELineType,
  MortgagePayableLess1YrRolloverType, DuDocumentationLevelType, SeFindingsCasualtyTheftLossLineType,
  SeScheduleEPartnershipSCorpType, SeFindingsCmpIncomeAnalysisLineType, SeFindingsSummarySCorpLineType,
  SeAnalysisResultType, SeEvaluationDataSourceType, SePartnerType, SeFindingsSummaryPartnershipLineType,
  SeFindingsSummaryCorpLineType, SeMonthlyQualifiedIncomeZeroMemos, SePYQualifiedIncomeZeroMemos, EmploymentOwnershipInterestProvidedType,
  SeRentalIncomeIrsFormType, SeRentalPropertyType, SeFairRentalDaysLessThan365DetailType, SeRentalMonthlyQualifiedIncomeZeroMemos,
  SeAdjustedRentalIncomeMemos
}
