<template>
  <div>

      <div class="row">

        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: {{taxFormPart==='1'?'Rental Income':''}} Form 1040 - Sch E ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>


          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

              <template v-if="index === 0">
                <template v-if="row.pageHeader">
                  <h4 class="mt-0 mb-2 se-bp-title-sb">{{row.pageHeader}}</h4>
                </template>

                <template v-if="taxFormPart==='1'">
                  <p>
                    A separate Income Calculator evaluation is required for each income-producing property used for loan qualification.
                    Provide all requested data exactly as it appears on the tax returns. Do not create an Income Calculator evaluation for a property the borrower will not own at the time of loan closing.
                  </p>

                </template>
                <template v-else-if="taxFormPart==='2'">
                  <p>To ensure you are reviewing the correct Schedule E, check that the name shown on return
                    in the header section of Schedule E is associated to the borrower who owns the business.
                  </p>
                </template>
              </template>

              <template v-if="!isHidden[index]">
                <template v-if="row.id === 'rentalPropertyOtherDeductibleMortgageInterest'">
                  <p>{{row.pageHeader}}</p>
                </template>

                <template v-if="row.id === 'pOrS'">
                  <p>Some borrowers have more than one business shown in Part II. Check that the Business Name shown on Line 28(a) and the Employer Identification Number
                    shown on Line 28(d) are associated to the business you want {{appName}} to evaluate.</p>
                </template>

                <label :for="row.id" class="se-std-field-group-label">
                  <span>{{row.line}}</span>
                  <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp"><i class="fa fa-info-circle"></i></a>
                </label>
              </template>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">

                  <template v-if="row.id === 'rentalPropertyAddress'">
                    <p class="mb-1">Physical address of property</p>
                    <input :id="row.id"
                             :readonly="isLocked"
                             class="bp-form-input bp-u-shadow-04 se-tax-field-input-long"
                             type="text"
                             @focus="highlightFocusedFormField(row);"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value" />
                  </template>

                  <template v-else-if="row.id === 'isPrincipalResidence'">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes, the property is the borrower’s principal residence</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No, the property is not the borrower’s principal residence</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isBorrowerWithHousingExpense' && !isHidden[index]">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The borrower owns a principal residence or pays rent</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">The borrower does not have any housing expense (Does not own a principal residence and does not pay rent)</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isSubjectProperty'">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes, the loan will refinance this property</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No, the loan will not refinance this property</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'rentalPropertyHousingPayment' && !isHidden[index]">
                    <p class="mb-2">
                      Provide the {{isRefinanceThisProperty ? 'proposed' : 'current'}} monthly housing expense (principal, interest, taxes, insurances, and homeowner’s association dues) for the rental property
                    </p>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="$v.rentalPropertyHousingPaymentField.value.$model" />
                    <div class="bp-help is-error" v-if="$v.rentalPropertyHousingPaymentField.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.rentalPropertyHousingPaymentField.withinLimits">Must be greater or equal $1.00</template>
                    </div>
                  </template>

                  <template v-else-if="row.id === 'rentalPropertyType' && !isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <el-select :id="row.id"
                               v-model="row.value"
                               :readonly="isLocked"
                               class="se-tax-field-input-40 bp-u-shadow-04"
                               @focus="highlightFocusedFormField(row)"
                               @blur="fieldIsFocused = false"
                               :disabled="row.isEmpty || isSkipped[index]"
                               placeholder="Select Type of Property">
                      <el-option
                        v-for="item in Enums.SeRentalPropertyType"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no value of this line</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isOtherPropertyTypeRentalIncome' && !isHidden[index]">
                    <p>
                      Look for an attachment in the tax return that describes the Other Property. Determine if the Other Property is best categorized as generating rental income according to the Fannie Mae Selling Guide. When determining if the property generates rental income, do not include income from accessory units or boarders.
                    </p>
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The Other Property described in the tax return attachment generates income that can be categorized as Rental Income according to the Fannie Mae Selling Guide</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">The Other Property described in the tax return attachment does not generate income that can be categorized as Rental Income according to the Fannie Mae Selling Guide, or a tax return attachment cannot be located</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'fairRentalDaysCount' && !isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="'###'"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="$v.fairRentalDaysCountField.value.$model" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no value on this line</span>
                    </radio>
                    <div class="bp-help is-error" v-if="$v.fairRentalDaysCountField.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.fairRentalDaysCountField.withinLimits">Must be between 0 and 365</template>
                    </div>
                  </template>

                  <template v-else-if="row.id === 'isFullYearOrNoHistoryRental' && !isHidden[index]">
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The loan file contains documentation the property was rented for less than the full tax year</span>
                    </radio>
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">The loan file contains documentation the property was rented for the full tax year or does not contain any documentation of property rental history</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'fairRentalDaysAltCount' && !isHidden[index]">
                    <p class="mb-2">Enter the Fair Rental Days documented in the loan file</p>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="'###'"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="$v.fairRentalDaysAltCountField.value.$model" />
                    <div class="bp-help is-error" v-if="$v.fairRentalDaysAltCountField.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.fairRentalDaysAltCountField.withinLimits">Must be between 0 and 365</template>
                    </div>
                  </template>

                  <template v-else-if="row.id === 'fairRentalDaysLessThan365DetailType' && !isHidden[index]">
                    <p class="mb-2">
                      Provide the reason Fair Rental Days are less than 365
                    </p>
                    <radio :label="Enums.SeFairRentalDaysLessThan365DetailType.acquiredDuringTaxYear"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The property was either acquired or was converted to a rental property during this tax year</span>
                    </radio>
                    <radio :label="Enums.SeFairRentalDaysLessThan365DetailType.rehabilitationRenovation"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">The property was undergoing significant renovation or rehabilitation and was not acquired during this tax year</span>
                    </radio>
                    <radio :label="Enums.SeFairRentalDaysLessThan365DetailType.unknown"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">I don’t know/Other</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isBorrowerWithOneYearRentalPropertyOwnershipHistory' && !isHidden[index]">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The borrower has at least a one-year history of receiving rental income or at least a one-year history of property management experience</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">The borrower has less than a one-year history of receiving rental income and less than a one-year history of property management experience</span>
                    </radio>
                  </template>

                  <template v-else-if="['rentsReceived', 'rentalPropertyAccessoryBoarderIncome', 'rentalPropertyInsurance',
                      'rentalPropertyMortgageInterestPaidToBanks', 'rentalPropertyOtherInterest', 'rentalPropertyOtherDeductibleMortgageInterest',
                      'rentalPropertyRepairs', 'rentalPropertyTax', 'rentalPropertyIndividualPropertyDepreciation',
                      'rentalPropertyTotalExpenses', 'rentalPropertyHomeownersAssociationDues'].includes(row.id) && !isHidden[index]">
                    <p class="mt-1" v-if="row.id === 'rentalPropertyHomeownersAssociationDues'">
                      Review Schedule E (particularly Lines 7, 11, and 19) and any attachments for homeowner’s association/condominium or cooperative dues.
                    </p>
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <template v-if="row.id === 'rentalPropertyAccessoryBoarderIncome'">
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                             type="text"
                             v-mask="row.mask"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="$v.rentalPropertyAccessoryBoarderIncomeField.value.$model"/>
                    </template>
                    <template v-else-if="row.id === 'rentalPropertyOtherDeductibleMortgageInterest'">
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                             type="text"
                             v-mask="row.mask"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="$v.rentalPropertyOtherDeductibleMortgageInterestField.value.$model"/>
                    </template>
                    <template v-else>
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                             type="text"
                             v-mask="row.mask"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value"/>
                    </template>
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1">
                      <span class="se-std-label" v-if="row.id === 'rentalPropertyAccessoryBoarderIncome'">There is no accessory dwelling unit or boarder income</span>
                      <span class="se-std-label" v-else-if="row.id === 'rentalPropertyOtherDeductibleMortgageInterest'">No deductible mortgage interest can be identified</span>
                      <span class="se-std-label" v-else-if="row.id === 'rentalPropertyHomeownersAssociationDues'">No homeowner’s association/condominium/cooperative dues can be identified on the tax returns</span>
                      <span class="se-std-label" v-else>There is no amount on this line</span>
                    </radio>
                    <div class="bp-help is-error" v-if="row.id === 'rentalPropertyOtherDeductibleMortgageInterest' && $v.rentalPropertyOtherDeductibleMortgageInterestField.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.rentalPropertyOtherDeductibleMortgageInterestField.withinLimits">Must be between $0 and ${{rentalPropertyOtherInterest}}</template>
                    </div>
                    <div class="bp-help is-error" v-if="row.id === 'rentalPropertyAccessoryBoarderIncome' && $v.rentalPropertyAccessoryBoarderIncomeField.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.rentalPropertyAccessoryBoarderIncomeField.withinLimits">Must be between $0 and ${{rentsReceived}}</template>
                    </div>
                  </template>




                  <template v-else-if="row.id === 'isPassiveLossOrUnreimbursedPartnershipExpense' && !isHidden[index]">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes, the checkbox on {{row.name}} is checked</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No, the checkbox on {{row.name}} is not checked</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'pOrS' && !isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input-short ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="psMask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           :value="row.value.toUpperCase()"
                           @input="row.value=$event.target.value.toUpperCase()"
                           />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no value on this line</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'passiveActivityLoss' && !isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no amount associated to the business that {{appName}} is evaluating,
                      or the only amount associated to the business that {{appName}} is evaluating has the name “UPE” or “Unreimbursed Partnership Expense” on Line 28(a).</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'nonPassiveK1IncomeLoss' && !isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no amount associated to the business that {{appName}} is evaluating.</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isUnreimbursedPartnershipExpense' && !isHidden[index]">
                    <p class="mt-1">
                      Does “UPE” or “Unreimbursed Partnership Expense” appear as the name for any row where the Employer Identification Number shown on Line 28(d) is associated to the business you want Income Calculator to evaluate?
                    </p>
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes, the name “UPE” or “Unreimbursed Partnership Expense” appears on a row where the Employer Identification Number shown on Line 28(d) is associated to the {{appName}} business.</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No, the name “UPE” or “Unreimbursed Partnership Expense” does not appear on any row or does not appear on a row where the Employer Identification Number shown on Line 28(d) is associated to the {{appName}} business.</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'unreimbursedPartnershipExpense' && !isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no UPE or Unreimbursed Partnership Expense amount associated to the business that {{appName}} is evaluating</span>
                    </radio>
                  </template>


                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>

        </div>
      </div>

    <se-help :visible.sync="showPageHelp">
        <template v-if="taxFormPart==='1'">
          <p>
            Use this screen to provide information about Rental Income.
          </p>
          <p>
            If a property is listed on the borrower’s tax returns, but the borrower no longer owns the property, or will not own the property at the time of the loan closing,
            no rental income for that property may be used in loan qualification. Do not create an Income Calculator evaluation for a property the borrower will not own at the time of loan closing.
          </p>
        </template>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          {{ appName }} may collect information not traditionally used in evaluating  income from self-employment, business ownership or rental property.
          Some data may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
    </se-help>

    <se-help :visible.sync="showFieldHelp.pOrS">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isPassiveLossOrUnreimbursedPartnershipExpense">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.passiveActivityLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.nonPassiveK1IncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isUnreimbursedPartnershipExpense">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.unreimbursedPartnershipExpense">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
          A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyAddress">
      <div class="se-field-help">
        <p>
        You may optionally provide the street address. Providing the street address may help you identify and track the correct rental income data when a
        borrower has more than one rental property. You should ensure the street address you provide does not contain any non-public personally identifying information.
        </p>
        <p>
        <b>FAQs:</b>
        </p>
        <p>
          Q. Will I need to complete a separate {{ appName }} evaluation for each rental property?<br>
		  A. Yes. If the borrower has multiple rental properties, you will need to complete a separate {{ appName }} evaluation for each rental property.<br>
        </p>
        <p>
         Q. The tax returns indicate the borrower has multiple rental properties, but the loan application appears to have merged some of the rental properties together.
          What address should I report?<br>
		 A. Providing a property address is optional. {{ appName }} does not attempt to match rental property incomes to another source, such as DU, using the property address.
          The loan application must separately identify each rental property with accurate data in Section 3 of the Uniform Residential Loan Application.
          If the borrower has multiple rental properties, you will need a separate {{ appName }} evaluation for each property.
          If the tax returns do not contain separate data for each rental property, {{ appName }} cannot be used for evaluation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isPrincipalResidence">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. {{ appName }} uses this information to determine if income data from other tax return forms or schedules may be needed.
          In addition, knowing if the property is the borrower's principal residence is necessary for {{ appName }} to generate the correct {{ appName }}
          Findings Report messages advising how the rental property data should be entered in DU.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isSubjectProperty">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. If the loan will refinance this property, the <b>PROPOSED</b> housing expense (PITIA) must be provided below.
          If the loan will not refinance this property, the <b>CURRENT</b> housing expense (PITIA) must be provided below.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isBorrowerWithHousingExpense">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. If the borrower has does not own a principal residence, and does not have a current housing expense (such as rent),
          then depending on the history of receiving rental income, the property's rental income may not be acceptable for use in loan qualification.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.1-08/rental-income" target="_blank">Rental Income Requirements</a>
      </u> in the Income Assessment section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyHousingPayment">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. The monthly principal, interest, tax, insurance and any homeowners association dues is needed to determine the net income or loss for the rental property.
          The amount provided must comply with applicable Selling Guide requirements.
        </p>
        <p>
          Q. There is no mortgage or lien on this property; what should I enter?<br>
		  A. Enter the sum of the monthly taxes, insurance and any homeowners association dues.
        </p>
        <p>
          Q. The borrower's mortgage payment includes escrows for taxes and insurances; what should I enter?<br>
		  A. Enter the sum of the monthly mortgage payment, any homeowners association dues, and any non-escrowed items that are part of the housing expense.
        </p>
         <p>
          Q. The borrower has subordinate (second/third/+) liens on the rental property; what should I enter as principal and interest?<br>
		  A. Include the monthly principal and interest of any subordinate liens in the total PITIA. If the loan will refinance this property, exclude the
           monthly principal and interest of any subordinate liens being paid off with the refinance proceeds.
        </p>
        <p>
          Q. The rental property has a lien or liens with payments that change, such as an ARM loan or a Home Equity Line of Credit (HELOC);
          what should I include as principal and interest in the PITIA?<br>
		  A. Enter the most recent PITIA payment made by the borrower.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyType">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is value "6 - Royalties" unable to be selected?<br>
		  A. {{ appName }} cannot evaluate royalty income at this time; please use an alternate method to calculate royalty income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isOtherPropertyTypeRentalIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I cannot locate an attachment in the tax return that describes the Other Property. Is there another way to determine if the
          Other Property is best categorized as generating rental income according to the Fannie Mae Selling Guide? <br>
		  A. You may ask the borrower or tax preparer for documentation to support the lender's determination that Other Property is best categorized as
          generating rental income according to the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.fairRentalDaysCount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no number on this line for the rental property being evaluated. Is that common?<br>
		  A. No. Most tax returns contain Fair Rental Days for each rental property. If the value is missing select, "There is no value on this line."
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isFullYearOrNoHistoryRental">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. What kind of documentation is required to substantiate the property was rented for less than the full tax year?<br>
		  A. For a recently acquired property, the purchase date can be documented using the settlement statement.
          For a property that was out of service due to significant renovation or rehabilitation, the lender may determine the Repair expenses on
          Schedule E, Line 14 provides sufficient documentation. Otherwise, the lender must ensure the loan file contains documentation that supports the
          number of Fair Rental Days used in the {{ appName }} evaluation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.fairRentalDaysAltCount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. What kind of documentation is required to substantiate the property was rented for less than the full tax year?<br>
		  A. For a recently acquired property, the purchase date can be documented using the settlement statement.
          For a property that was out of service due to significant renovation or rehabilitation, the lender may determine the Repair expenses on
          Schedule E, Line 14 provides sufficient documentation. Otherwise, the lender must ensure the loan file contains documentation that supports the
          number of Fair Rental Days used in the {{ appName }} evaluation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.fairRentalDaysLessThan365DetailType">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. Fair Rental Days are required to calculate the monthly qualifying income. {{ appName }} will use 365 days unless another number of Fair Rental Days
          is provided and the loan file contains documentation supporting less than 365 Fair Rental Days.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isBorrowerWithOneYearRentalPropertyOwnershipHistory">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. The borrower's history of property ownership or management of rental properties is necessary for {{ appName }} to determine if there are any
          restrictions on the amount of rental income that can be used for loan qualification.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentsReceived">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no number on this line for the rental property being evaluated. Is that common? <br>
		  A. No. Most tax returns contain Rents received for each rental property. If the value is missing select, "There is no amount on this line."
        </p>
        <p>
          Q. Why is this information important to {{ appName }}?<br>
		  A. Rental income received is required to calculate the monthly qualifying income. {{ appName }} will use $0 if no amount is provided.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyAccessoryBoarderIncome">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. What is an accessory dwelling unit?<br>
		  A. An accessory dwelling unit is typically an additional living area independent of the primary dwelling that may have been added to,
          created within, or detached from a primary one-unit dwelling. An accessory dwelling unit must provide for living, sleeping, cooking, and
          bathroom facilities and be on the same parcel as the primary one-unit dwelling.
        </p>
          <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about <u><a href="https://selling-guide.fanniemae.com/sel/b2-3-04/special-property-eligibility-considerations" target="_blank">ADUs</a>
      </u> in the Special Property Eligibility Considerations section of the Fannie Mae Selling Guide.
        </p>
      	<p>
          Q. What is boarder income?<br>
		  A. Boarder income is rent paid for a portion of the borrower's living space by a non-borrower who has a history of shared residency with the borrower.
        </p>
        <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
        Learn more about <u><a href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income" target="_blank">Boarder Income</a>
      </u> in the Other Sources of Income section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no accessory dwelling unit or boarder income for the rental property being evaluated. Is that common?<br>
		  A. Yes. Most properties do not have accessory units or boarders. If there is no value, select "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. Accessory dwelling unit and boarder income have unique eligibility requirements that need to be evaluated by the lender.
          Eligible amounts must be reported separately to DU and should not be considered rental income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyInsurance">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		  A. No. Unless the property is vacant land, most tax returns contain Insurance amounts for each rental property.
          If the property is not vacant land, a missing insurance value may indicate that insurance expenses are paid as part of homeowners association dues.
          If the value is missing, select "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. The calculation of qualifying rental income allows adding back the principal, interest, taxes, insurance and homeowners association dues
          expenses to the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyMortgageInterestPaidToBanks">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		  A. If there is no mortgage debt on the rental property, this line may be blank. If the value is missing select, "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. The calculation of qualifying rental income allows adding back the principal, interest, taxes, insurance and homeowners association dues
          expenses to the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyOtherInterest">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		  A. If there is no debt on the rental property, this line may be blank. If the value is missing select, "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. Income Calculator uses this information to determine if income data from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyOtherDeductibleMortgageInterest">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. I am unable to locate any other deductible mortgage interest for the rental property being evaluated. Is that common?<br>
		 A. Yes, most rental properties do not have other deductible mortgage interest.
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. The calculation of qualifying rental income allows adding back the principal, interest, taxes, insurance and homeowners association dues
          expenses to the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyRepairs">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		 A. No. Most rental properties have Repair expenses. If the value is missing select, "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. Income Calculator will attempt to identify if the amount of repairs can be used to validate Fair Rental Days less than 365 due to
          significant renovation or rehabilitation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyTax">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		 A. No. A missing tax value may indicate that property taxes are paid as part of homeowners association dues.
          If the value is missing select, "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. The calculation of qualifying rental income allows adding back the principal, interest, taxes, insurance and homeowners association dues
          expenses to the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyIndividualPropertyDepreciation">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		 A. No. Most rental properties have a Depreciation expense. If the value is missing select, "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. Rental property depreciation and depletion can be added back to the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyTotalExpenses">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		 A. No. Most rental properties have Total expenses. If the value is missing select, "There is no amount on this line."
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. The calculation of qualifying rental income requires subtraction of expenses other than principal, interest, taxes, insurance and
          homeowners association dues from the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.rentalPropertyHomeownersAssociationDues">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. There is no amount on this line for the rental property being evaluated. Is that common?<br>
		 A. If the rental property is located in a condominium, cooperative or planned unit development (PUD), then homeowners association dues may be an expense.
        </p>
        <p>
         Q. The rental property is a condominium, cooperative or planned unit development (PUD). There is no specified amount on the tax return for homeowners association dues.
          Can I enter the Cleaning and maintenance fees from Line 7 or the Management fees from Line 11 as the homeowners association dues?<br>
		 A. No. The homeowners association dues must be specifically identified in the tax returns, either on Schedule E or on an attachment, or the lender must
          obtain documentation, such as written confirmation from the tax preparer to validate the homeowners association dues that were included as an a rental property expense.
        </p>
        <p>
         Q. Why is this information important to {{ appName }}?<br>
		 A. The calculation of qualifying rental income requires subtraction of expenses other than principal, interest, taxes, insurance and
          homeowners association dues expenses from the borrower’s cash flow.
        </p>
      </div>
    </se-help>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :display-skip="this.isSkipFormBtnDisplayed"
      :display-un-skip="this.isUnSkipFormBtnDisplayed"
      :disable-submit="true"
      :disable-save="true"
      :disable-next="false"
      :disable-previous="false"
      @seSkip="skipTaxForm"
      @seUnSkip="unSkipTaxForm"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import Vue from 'vue'
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, IconTrash, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import Swal from 'sweetalert2'
import { Select, Option, Collapse, CollapseItem } from 'element-ui'
import {SET_REQUEST_DATA} from 'src/store/actions/se'
import Enums from 'src/model/enums'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import appNameMixin from '../../../mixins/AppNameMixin'
import {taxMultiPartFormMixin} from "@/mixins/TaxMultiPartFormMixin";
export default {
  mixins: [taxMultiPartFormMixin, appNameMixin],
  components: {
    IconTrash,
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      psMask: [/[PpSs]/]
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    },
    fairRentalDaysCountField: {
      value: {
        withinLimits(value) {
          return this.withinLimitsOrEmptyAsValue(value, 0, 365);
        }
      }
    },
    fairRentalDaysAltCountField: {
      value: {
        withinLimits(value) {
          return this.withinLimitsOrEmptyAsValue(value, 0, 365);
        }
      }
    },
    rentalPropertyOtherDeductibleMortgageInterestField: {
      value: {
        withinLimits(value) {
          return this.withinLimitsOrEmptyAsValue(value, 0, this.rentalPropertyOtherInterest);
        }
      }
    },
    rentalPropertyAccessoryBoarderIncomeField: {
      value: {
        withinLimits(value) {
          return this.withinLimitsOrEmptyAsValue(value, 0, this.rentsReceived);
        }
      }
    },
    rentalPropertyHousingPaymentField: {
      value: {
        withinLimits(value) {
          if (this.taxFormPart !== '1') return true
          if (!value) return false
          let numValue = Number(value.replace(/,|\$|\%/g, '')) ?? 0
          return numValue >= 1.0
        }
      }
    }

  },
  computed: {
    isSkipped() {
      //use map to return an array of skipped flags. one flag per field
      return this.tableData.map(f => {
        let skipped = false;
        if (this.skippedForm) return true

        if (f.id === "pOrS") {
          if ([Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.partnership].includes(this.$store.getters.getBusinessStructureProvided)) skipped = true

        } else if (["isPassiveLossOrUnreimbursedPartnershipExpense", "passiveActivityLoss", "nonPassiveK1IncomeLoss"].includes(f.id)) {
          if (!this.isPassiveLossOrUnreimbursedPartnershipExpenseDisplayed) skipped = true

        } else if (["isUnreimbursedPartnershipExpense"].includes(f.id)) {
          if (!this.isUnreimbursedPartnershipExpenseDisplayed) skipped = true

        } else if (["unreimbursedPartnershipExpense"].includes(f.id)) {
          if (!this.isUnreimbursedPartnershipExpenseAmtDisplayed) skipped = true

        } else if ("isBorrowerWithHousingExpense" === f.id) {
          if (!this.isNotPrincipalResidence) skipped = true

        } else if ("isOtherPropertyTypeRentalIncome" === f.id) {
          if (Enums.SeRentalPropertyType.other.id !== this.getValue("rentalPropertyType")) skipped = true

        } else if ("isFullYearOrNoHistoryRental" === f.id) {
          if (!this.getField("fairRentalDaysCount").isEmpty) skipped = true

        } else if ("fairRentalDaysAltCount" === f.id) {
          if ("No" !== this.getValue("isFullYearOrNoHistoryRental")) skipped = true

        } else if ("fairRentalDaysLessThan365DetailType" === f.id) {
          if (!this.isDisplayFairRentalDaysLt365Detail) skipped = true

        } else if ("isBorrowerWithOneYearRentalPropertyOwnershipHistory" === f.id) {
          if (!this.isDisplayRentalPropertyOwnershipHistory) skipped = true

        } else if ("rentalPropertyAccessoryBoarderIncome" === f.id) {
          if (!this.isDisplayRentalPropertyAccessoryBoarderIncome) skipped = true

        } else if ("rentalPropertyOtherDeductibleMortgageInterest" === f.id) {
          if (this.getValueAsNumber("rentalPropertyOtherInterest") === 0) skipped = true
        }

        return skipped
      })
    },
    isHidden() {
      //certain fields should be hidden when they are skipped
      return this.tableData.map((f,index) => {
        if (this.isSkipped[index]) {
            return this.isSkipped[index]
        }
        return false
      })
    },
    isSkipFormBtnDisplayed() {
      //allow skip only for LLC and Unknown and for CY
      return ([Enums.SeBusinessStructureType.llc, Enums.SeBusinessStructureType.unknown].includes(this.$store.getters.getBusinessStructureProvided))
        && this.getPY1 === this.taxFormYear && !this.skippedForm
    },
    isUnSkipFormBtnDisplayed() {
      return (this.getPY1 === this.taxFormYear) && this.skippedForm
    },
    isPassiveLossOrUnreimbursedPartnershipExpenseDisplayed() {
      return [Enums.SeBusinessStructureType.sCorp, Enums.SeBusinessStructureType.partnership].includes(this.$store.getters.getBusinessStructureProvided)
        || ['P', 'S'].includes(this.getValue("pOrS"))
    },
    isUnreimbursedPartnershipExpenseDisplayed() {
      return (Enums.SeBusinessStructureType.partnership === this.$store.getters.getBusinessStructureProvided || 'P' === this.getValue("pOrS"))
        && 'Yes' === this.getValue("isPassiveLossOrUnreimbursedPartnershipExpense")
    },
    isUnreimbursedPartnershipExpenseAmtDisplayed() {
      return this.isUnreimbursedPartnershipExpenseDisplayed
        && 'Yes' === this.getValue("isUnreimbursedPartnershipExpense")
    },
    isRefinanceThisProperty() {
      return 'Yes' === this.getValue("isSubjectProperty")
    },
    isNotPrincipalResidence() {
      return 'No' === this.getValue("isPrincipalResidence")
    },
    fairRentalDaysCountField() {
      return this.getField("fairRentalDaysCount")
    },
    fairRentalDaysAltCountField() {
      return this.getField("fairRentalDaysAltCount")
    },
    isDisplayFairRentalDaysLt365Detail() {
      let fairRentalDaysCount = this.getValueAsNumber("fairRentalDaysCount")
      let fairRentalDaysAltCount = this.getValueAsNumber("fairRentalDaysAltCount")
      return (this.fairRentalDaysCountField.value && fairRentalDaysCount >= 0 && fairRentalDaysCount < 365)
        || (this.fairRentalDaysCountField.isEmpty && this.fairRentalDaysAltCountField.value && fairRentalDaysAltCount >= 0 && fairRentalDaysAltCount < 365)
    },
    isDisplayRentalPropertyOwnershipHistory() {
      let fairRentalDaysCount = this.getValueAsNumber("fairRentalDaysCount")
      let fairRentalDaysAltCount = this.getValueAsNumber("fairRentalDaysAltCount")
      return (this.fairRentalDaysCountField.value && fairRentalDaysCount !== 365)
        || (this.fairRentalDaysCountField.isEmpty && this.fairRentalDaysAltCountField.value && fairRentalDaysAltCount !== 365 )
    },
    isDisplayRentalPropertyAccessoryBoarderIncome() {
      return this.getValueAsNumber("rentsReceived") > 0
        && 'Yes' === this.getValue("isPrincipalResidence")
        && this.isRefinanceThisProperty
    },
    rentalPropertyOtherInterest() {
      return this.getValueAsNumber("rentalPropertyOtherInterest")
    },
    rentsReceived() {
      return this.getValueAsNumber("rentsReceived")
    },
    rentalPropertyOtherDeductibleMortgageInterestField() {
      return this.getField('rentalPropertyOtherDeductibleMortgageInterest')
    },
    rentalPropertyAccessoryBoarderIncomeField() {
      return this.getField('rentalPropertyAccessoryBoarderIncome')
    },
    rentalPropertyHousingPaymentField() {
      return this.getField('rentalPropertyHousingPayment')
    },

  },
  methods: {
    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      if (Enums.SeRentalPropertyType.other.id === this.getValue("rentalPropertyType")
        && "No" === this.getValue("isOtherPropertyTypeRentalIncome")) {
        this.alertOtherPropertyType()
        return
      }

      this.doNext();
    },
    doNext() {
      this.saveToStore()

      //this form will change the evaluated business structure, so the
      this.updateSelectedTaxForms()

      this.saveRequest()
      let ri = this.seTaxFormRoutes.indexOf(this.$router.currentRoute.path);
      if (ri < this.seTaxFormRoutes.length - 1) {
        this.$router.push(this.seTaxFormRoutes[ri + 1])
      } else {
        this.$router.push('/se-eval/business-length')
      }
    },
    previous() {
      let ri = this.seTaxFormRoutes.indexOf(this.$router.currentRoute.path);
      if (ri > 0) {
        this.$router.push(this.seTaxFormRoutes[ri - 1])
      } else {
        this.$router.push('/se-eval/tax-form-select')
      }
    },
    updateSelectedTaxForms() {
      //this form determines the business structure in case of LLC/Unknown
      let updatedSelectedTaxForms = []
      this.seRequest.selectedTaxForms.forEach(stf => {
        let updatedStf = {year: stf.year, formIds: stf.formIds.slice()}
        updatedSelectedTaxForms.push(updatedStf)

        if (stf.formIds && stf.formIds.length > 0) {
          let businessStructure = this.$store.getters.getBusinessStructureEvaluatedAndDerived.evaluated
          let formIdToAdd = undefined
          if (businessStructure === Enums.SeBusinessStructureType.soleProprietorship) {
            //noop
          } else if (businessStructure === Enums.SeBusinessStructureType.corp) {
            formIdToAdd = Enums.SeTaxFormType.tf1120
          } else if (businessStructure === Enums.SeBusinessStructureType.sCorp) {
            formIdToAdd = Enums.SeTaxFormType.tf1120s
          } else if (businessStructure === Enums.SeBusinessStructureType.partnership) {
            formIdToAdd = Enums.SeTaxFormType.tf1065
          }
          if (formIdToAdd && !updatedStf.formIds.includes(formIdToAdd)) {
            updatedStf.formIds.splice(updatedStf.formIds.indexOf(Enums.SeTaxFormType.tf1040c), 1)
            updatedStf.formIds.push(formIdToAdd)
          }
        }
      })
      this.$store.commit(SET_REQUEST_DATA, { selectedTaxForms: updatedSelectedTaxForms })
    },
    handleHelp(index, row) {
      row.showHelp = true;
    },

    alertOtherPropertyType() {
      Swal.fire({
        title: `Warning!`,
        icon: 'warning',
        html: "If Other Property cannot be categorized as rental income according to the Fannie Mae Selling Guide, Income Calculator cannot calculate the qualifying income.",
        width: 600,
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        cancelButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.doNext()
        }
      })
    },

  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
