<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1125-E ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

              <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.sCorp">
                <template v-if="row.line && !isHidden[index]">

                  <template v-if="row.id === 'compensationOfOfficers'">
                    Find the line associated to the borrower whose income is being evaluated.
                    <br>
                  </template>

                  <label :for="row.id" class="se-std-field-group-label mt-3">
                    <span>{{row.line}}</span>
                  </label>
                  <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </template>
              </template>

              <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.corp">
                <template v-if="row.id === 'corporationOwnershipInterestGte25Pct' && form1125eIncluded === 'No'">
                  <p>
                    Because Form 1125-E is not included in the tax returns, provide the corporation ownership information for borrowers on the loan.
                    <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="show1125ENotIncludedHelp = true">
                      <i class="fa fa-info-circle"></i>
                    </a>
                  </p>
                </template>

                <template v-if="row.line && !isHidden[index]">
                  <label :for="row.id" class="se-std-field-group-label mt-3">
                    <template v-if="row.id === 'allCorporationOwnersAreBorrowers' && form1125eIncluded === 'No'">
                      <span>Are all owners of the corporation borrowers on the loan?</span>
                    </template>
                    <template v-else>
                      <span>{{row.line}}</span>
                    </template>
                  </label>
                  <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </template>
              </template>


              <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                <template v-if="row.id === 'form1125eIncluded'">

                  <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.sCorp">
                    <p>
                      Some S-Corporations use Form 1125-E to report Compensation of Officers to the IRS.
                      This form lists the name of each officer in the S-Corporation and the compensation amount. Look for this form in the tax returns.
                    </p>
                  </template>
                  <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.corp">
                    <p>
                      Some corporations use Form 1125-E to report compensation of officers to the IRS.
                      This form lists the name of each officer in the corporation and his/her percentage of ownership.
                      When the lender includes business tax returns in the income evaluation (the lender has not waived the requirement for business tax returns), look for this form in the tax returns.
                    </p>
                  </template>

                  <radio label="Yes"
                         v-model="row.value"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mb-1"><span class="se-std-label">Yes, Form 1125-E is included in the tax returns</span>
                  </radio>
                  <radio label="No"
                         v-model="row.value"
                         :disabled="isSkipped[index] || isLocked"
                         :class="{'opacity-5': isSkipped[index]}"
                         class="mt-1">
                    <span v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.corp" class="se-std-label">No, Form 1125-E is not included in the tax returns, or the lender has waived the requirement for business tax returns in accordance with the Fannie Mae Selling Guide</span>
                    <span v-else class="se-std-label">No, Form 1125-E is not included in the tax returns</span>
                  </radio>
                </template>

                <template v-if="businessStructureEvaluated === Enums.SeBusinessStructureType.sCorp">

                  <template v-if="row.id === 'compensationOfOfficers'">
                    <template v-if="!isHidden[index]">
                      <radio :label="false"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1"><span class="se-std-label">Enter {{row.name}}</span>
                      </radio>
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input"
                             type="text"
                             v-mask="row.mask"
                             @focus="highlightFocusedFormField(row);"
                             @blur="fieldIsFocused = false;"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value" />
                      <radio :label="true"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">The borrower is not listed on this form, or the borrower’s Amount of compensation is not shown on this form</span>
                      </radio>
                      <br>
                      <p>
                        In most cases the borrower receives a W-2 form for the Compensation of officers amount.
                        If a W-2 form is not available, {{ appName }} will consider the Compensation of officers amount in its qualifying income calculation if it is determined the amount was included as income on the borrower’s individual (personal) tax return (Line 1 of Form 1040).
                      </p>
                    </template>

                  </template>

                  <template v-else-if="row.id === 'isCompensationOfOfficersIncludedAsIncome' && !isHidden[index]">
                    Was the Compensation of officers amount included as income in the borrower’s personal tax return?

                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No</span>
                    </radio>
                  </template>

                </template>

                <template v-else-if="businessStructureEvaluated === Enums.SeBusinessStructureType.corp">

                  <template v-if="row.id === 'businessOwnerCount'">
                    <template v-if="!isHidden[index]">
                      <radio :label="false"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1">
                        <span class="se-std-label">{{row.name}}
                          <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                            <i class="fa fa-info-circle"></i>
                          </a>
                        </span>
                      </radio>
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                             type="text"
                             v-mask="'######'"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value" />
                      <radio :label="true"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">There are no officers named in column A</span>
                      </radio>

                      <div class="bp-help is-error" v-if="$v.businessOwnerCountField.$error">
                        <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                        <template v-if="!$v.businessOwnerCountField.withinLimits">Must be between 1 and 999999</template>
                      </div>
                    </template>
                  </template>

                  <template v-else-if="row.id === 'commonStockOwnershipPercent'">
                    <template v-if="!isHidden[index]">
                      <radio :label="false"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1">
                        <span class="se-std-label">{{row.name}}</span>
                      </radio>
                      <input :id="row.id"
                             :readonly="isLocked"
                             class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                             type="text"
                             v-mask="row.mask"
                             @focus="highlightFocusedFormField(row)"
                             @blur="fieldIsFocused = false"
                             :disabled="row.isEmpty || isSkipped[index]"
                             v-model="row.value" />
                      <radio :label="true"
                             v-model="row.isEmpty"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">The borrower's percent of common stock is not shown on this form</span>
                      </radio>
                      <div class="bp-help is-error" v-if="$v.commonStockOwnershipPercentField.$error">
                        <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                        <template v-if="!$v.commonStockOwnershipPercentField.withinLimits">Must be between 1 and 100</template>
                      </div>

                    </template>
                  </template>

                  <template v-else-if="row.id === 'corporationOwnershipInterestGte25Pct'">
                    <template v-if="!isHidden[index]">
                      <radio label="Yes"
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1"><span class="se-std-label">Yes, the borrower owned 25% or more of the corporation</span>
                      </radio>
                      <radio label="No"
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">No, the borrower owned less than 25% of the corporation</span>
                      </radio>
                    </template>
                  </template>

                  <template v-else-if="row.id === 'allCorporationOwnersAreBorrowers'">
                    <template v-if="!isHidden[index]">

                      <template v-if="form1125eIncluded === 'Yes' && !businessOwnerCountField.isEmpty">
                        <radio label="Yes"
                               v-model="row.value"
                               :disabled="isSkipped[index] || isLocked"
                               :class="{'opacity-5': isSkipped[index]}"
                               class="mb-1"><span class="se-std-label">Yes, all officers named in column A are borrowers on the loan</span>
                        </radio>
                        <radio label="No"
                               v-model="row.value"
                               :disabled="isSkipped[index] || isLocked"
                               :class="{'opacity-5': isSkipped[index]}"
                               class="mt-1"><span class="se-std-label">No, there are one or more officers named in column A who are not borrowers on the loan</span>
                        </radio>
                      </template>

                      <template v-else>
                        <radio label="Yes"
                               v-model="row.value"
                               :disabled="isSkipped[index] || isLocked"
                               :class="{'opacity-5': isSkipped[index]}"
                               class="mb-1">
                          <span class="se-std-label">Yes, all owners of the corporation are borrowers on the loan.</span>
                          <div class="mt-1">
                            Total number of corporation owners
                            <input :id='corporationOwnerCount'
                                   :readonly="isLocked"
                                   class="se-tax-field-input ml-2 mt-0 bp-form-input bp-u-shadow-04"
                                   type="text"
                                   v-mask="'######'"
                                   :disabled="corporationOwnerCountField.isEmpty || isSkipped[5]"
                                   v-model="corporationOwnerCountField.value" />
                          </div>
                        </radio>
                        <radio label="No"
                               v-model="row.value"
                               :disabled="isSkipped[index] || isLocked"
                               :class="{'opacity-5': isSkipped[index]}"
                               class="mt-1"><span class="se-std-label">No, there are owners of the corporation who are not borrowers on the loan.</span>
                        </radio>
                      </template>
                    </template>
                  </template>

                </template>


              </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>

      </div>

    <se-help :visible.sync="showPageHelp">
        <p>
          Use this screen to provide information from Form 1125-E, Compensation of Officers.<br>
          
          For an S-Corporation, when no W-2 data is provided, {{ appName }} will attempt to determine if the borrower received wage income from the business.
          Compensation of Officers amounts will be considered as monthly qualifying income if it can be determined the amount was included in the borrower’s individual (personal) tax returns.
        </p>
      <b>FAQs:</b>
        <p>
          Q. There is no Form 1125-E in the borrower's S-Corporation tax return. Is that common?<br>
          A. The IRS does not require all S-Corporations to file Form 1125-E.
          If the borrower’s complete tax returns do not include a Form 1125-E, select “No, Form 1125-E is not included in the tax returns, 
          or the lender has waived the requirement for business tax returns in accordance with the Fannie Mae Selling Guide.”
        </p>
      	<p>
      	Q. I can’t locate this form in the borrower’s C-Corporation tax returns. What should I do?<br>
	 	A. The borrower is required to provide copies of the tax returns that were filed with the IRS, including all applicable schedules. 
          However, the IRS does not require all Corporations to file Form 1125-E. If the borrower’s complete tax returns do not include a Form 1125-E, 
          select “No, Form 1125-E is not included in the tax returns, or the lender has waived the requirement for business tax returns in accordance 
          with the Fannie Mae Selling Guide.”
      	</p>
    </se-help>

      <se-help :visible.sync="showFieldHelp.businessOwnerCount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
         Q. Why is this important to {{ appName }}?
         A. {{ appName }} will use this information to determine if additional corporation ownership information is needed in order to calculate the appropriate amount of qualifying income.
        </p>
        <p>
        Q. There are officers listed in column A who have no common or preferred stock ownership. Do I include these officers in the count?<br>
		A. No, you should exclude officers from the count if they do not have any percentage of common or preferred stock ownership.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.commonStockOwnershipPercent">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} will use this information to determine if additional corporation ownership information is needed in order to calculate the appropriate amount of qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.allCorporationOwnersAreBorrowers">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
           A. {{ appName }} will use this information to determine if corporate income can be used in the monthly qualifying income calculation.
        </p>
        <p>
          Q. How do I obtain this information?<br>
          A. The Fannie Mae Selling Guide does not specify a particular source or documentation method to use when determining Corporate ownership.
          This information may be available from authoritative sources such as state licensing records or from commercial databases. The borrower may
          be able to provide documentation such as Articles of Incorporation or Bylaws that identify the Corporation’s owners.
        </p>
        <p>
          Q. I cannot obtain this information. What should I do?<br>
          A. Select “No, there are owners of the corporation who are not are borrowers on the loan”. {{ appName }} will not consider Corporate income in
          the monthly qualifying income calculation. The borrower’s W-2 income from the Corporation will be considered.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.corporationOwnershipInterestGte25Pct">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Currently {{ appName }} cannot evaluate income when the borrower owns less than 25% of the business.
        </p>
        <p>
          Q. How do I obtain this information?<br>
          A. The Fannie Mae Selling Guide does not specify a particular source or documentation method to use when determining Corporate ownership.
          This information may be available from authoritative sources such as state licensing records or from commercial databases. The borrower may
          be able to provide documentation such as Articles of Incorporation or Bylaws that identify the Corporation’s owners.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="show1125ENotIncludedHelp">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} will use this information to determine if corporate income can be used in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.compensationOfOfficers">
      <div class="se-field-help">
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
          Carefully compare the names and social security numbers on Form 1125-E to the borrower information to ensure the Amount of compensation is associated to the borrower whose income is being evaluated.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isCompensationOfOfficersIncludedAsIncome">
      <div class="se-field-help">
        <p>
          When no W-2 data is provided, {{ appName }} will attempt to determine if the borrower received wage income from the business. Compensation of Officers amounts will be considered as monthly qualifying income if it can be determined the amount was included in the borrower’s individual (personal) tax returns.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. The income must be included in the borrower’s individual (personal) tax returns in order to be used for loan qualification.
        </p>
        <p>
          Q. How do I determine if the Compensation of Officers amount was included in the borrower’s individual (personal) tax returns?<br>
          A. Ensure you have complete personal tax returns, including all schedules, forms, and attachments. In some cases, the tax return will contain an attachment or a statement that lists the sources of income reported on Form 1040, Line 1. You may need additional documentation if the tax returns do not contain sufficient information to make this determination.
        </p>
        <p>
          Q. I think there may be an issue with a missing W-2 Form. Do I need to notify the borrower of this potential error? Is the borrower required to provide a W-2 Form?<br>
          A. You are not required to notify the borrower of any potential errors as a part of using Income Calculator. The recommended documentation of wage income is a W-2 Form. The borrower is not required to provide a W-2 Form, however Compensation of Officers amounts will not be considered as monthly qualifying income if it cannot be determined the amount was included in the borrower’s individual (personal) tax returns.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>


    <se-buttons
      :display-submit="false"
      :display-save="false"
      @seNext="next"
      @sePrevious="previous">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import appNameMixin from '../../../mixins/AppNameMixin'
export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      show1125ENotIncludedHelp: false
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    },
    businessOwnerCountField: {
      withinLimits(field) {
        return this.withinLimitsOrEmpty(field, 1, 999999);
      }
    },
    commonStockOwnershipPercentField: {
      withinLimits(field) {
        return this.withinLimitsOrEmpty(field, 1, 100);
      }
    }
  },
  computed: {
    businessStructureEvaluated() {
      return this.$store.getters.getBusinessStructureEvaluatedAndDerived.evaluated
    },
    isCorpMode() {
      return this.businessStructureEvaluated === this.Enums.SeBusinessStructureType.corp
    },
    isSCorpMode() {
      return this.businessStructureEvaluated === this.Enums.SeBusinessStructureType.sCorp
    },
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true

        //different group of fields used in Corp vs SCorp modes
        if (this.isSCorpMode) {
          if (["businessOwnerCount", "commonStockOwnershipPercent", "allCorporationOwnersAreBorrowers", "corporationOwnerCount", "corporationOwnershipInterestGte25Pct"].includes(f.id)) return true

          if (["compensationOfOfficers", "isCompensationOfOfficersIncludedAsIncome"].includes(f.id)) {
            return this.form1125eIncluded !== 'Yes'
          }

        } else if (this.isCorpMode) {
          if (["compensationOfOfficers", "isCompensationOfOfficersIncludedAsIncome"].includes(f.id)) return true

          if ("businessOwnerCount" === f.id) {
            return this.form1125eIncluded !== 'Yes'

          } else if ("commonStockOwnershipPercent" === f.id ) {
            return !(this.form1125eIncluded === 'Yes' && this.getValueAsNumber("businessOwnerCount") > 1)

          } else if ("allCorporationOwnersAreBorrowers" === f.id) {
            return !((this.getValueAsNumber("businessOwnerCount") > 1) || (this.form1125eIncluded === 'No') || (this.form1125eIncluded === 'Yes' && this.getField("businessOwnerCount").isEmpty))

          } else if ("corporationOwnerCount" === f.id ) {
            return  (this.form1125eIncluded === 'Yes' && !this.businessOwnerCountField.isEmpty) || this.allCorporationOwnersAreBorrowers !== 'Yes'
          }
        }

        return false;
      })
    },
    isHidden() {
      //certain fields should be hidden when they are skipped
      return this.tableData.map((f,index) => {

        if (this.isSCorpMode) {
          return this.isSkipped[index]

        } else if (this.isCorpMode) {
          if (["businessOwnerCount", "commonStockOwnershipPercent", "allCorporationOwnersAreBorrowers", "compensationOfOfficers", "isCompensationOfOfficersIncludedAsIncome"].includes(f.id)) {
            return this.isSkipped[index]

          } else if ("corporationOwnershipInterestGte25Pct" === f.id) {
            return !(this.form1125eIncluded === 'No' || (this.form1125eIncluded === 'Yes' && this.getField("businessOwnerCount").isEmpty) || (this.businessOwnerCount > 1 && this.getField("commonStockOwnershipPercent").isEmpty))
          }
        }

        return false
      })
    },

    form1125eIncluded() {
      return this.getValue("form1125eIncluded")
    },
    allCorporationOwnersAreBorrowers() {
      return this.getValue("allCorporationOwnersAreBorrowers")
    },
    businessOwnerCount() {
      return this.getValueAsNumber("businessOwnerCount")
    },
    corporationOwnerCount() {
      return this.getValueAsNumber("corporationOwnerCount")
    },
    businessOwnerCountField() {
      return this.getField("businessOwnerCount")
    },
    corporationOwnerCountField() {
      return this.getField("corporationOwnerCount")
    },
    commonStockOwnershipPercentField() {
      return this.getField("commonStockOwnershipPercent")
    },

  },
  watch: {
    form1125eIncluded(isIncluded) {
      this.setFieldsForMode();
      if (this.isCorpMode) {
        let businessOwnerCountField = this.getField('businessOwnerCount');
        if (businessOwnerCountField) {
          if (isIncluded === 'No') {
            businessOwnerCountField.isEmpty = true
            businessOwnerCountField.value = ''
          }
        }
      }
    },
    allCorporationOwnersAreBorrowers(allBorrowers) {
      if (this.isCorpMode) {
        if (this.corporationOwnerCountField) {
          if (allBorrowers === 'Yes') {
            this.corporationOwnerCountField.isEmpty = false
          } else {
            this.corporationOwnerCountField.isEmpty = true
            this.corporationOwnerCountField.value = ''
          }
        }
      }
    },
    businessOwnerCount(ownerCount) {
      if (this.isCorpMode) {
        let commonStockOwnershipPercent = this.getField('commonStockOwnershipPercent');
        if (commonStockOwnershipPercent) {
          if (this.form1125eIncluded === 'Yes' && ownerCount > 1) {
            commonStockOwnershipPercent.isEmpty = false
            if (commonStockOwnershipPercent.value == '100%') {
              commonStockOwnershipPercent.value = ''
            }

          } else if (this.form1125eIncluded === 'Yes' && ownerCount == 1) {
            commonStockOwnershipPercent.isEmpty = false
            commonStockOwnershipPercent.value = '100%'

            let allCorporationOwnersAreBorrowers = this.getField('allCorporationOwnersAreBorrowers');
            allCorporationOwnersAreBorrowers.isEmpty = false
            allCorporationOwnersAreBorrowers.value = 'Yes'

          } else {
            commonStockOwnershipPercent.isEmpty = true
            commonStockOwnershipPercent.value = ''
          }
        }
      }
    },

  },
  methods: {
    setFieldsForMode() {
      if (this.isSCorpMode) {
        ["businessOwnerCount", "commonStockOwnershipPercent", "allCorporationOwnersAreBorrowers", "corporationOwnerCount", "corporationOwnershipInterestGte25Pct"].forEach(f => this.setFieldEmpty(f))
      } else if (this.isCorpMode) {
        ["compensationOfOfficers", "isCompensationOfOfficersIncludedAsIncome"].forEach(f => this.setFieldEmpty(f))
      }
    },
    handleHelp(index, row) {
      row.showHelp = true;
    },

  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
