import Enums from "../../../../model/enums";
import {ty} from "./ty";

export default {
  id: Enums.SeTaxFormType.tf1040s1,
  pdf: `static/forms/${ty}/${ty}_1040s1.pdf`,
  route: `/se-eval/std-tax-form-1040s1/${Enums.SeTaxFormType.tf1040s1}/${ty}`,
  fields: [
    {
      id: "businessIncomeLoss",
      fieldName: "form1[0].Page1[0].f1_07[0]",
      label: "Business income or (loss)",
      line: "Line 3",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "rentalReRoyaltiesPartnerships",
      formPart: 0,
      fieldName: "form1[0].Page1[0].f1_09[0]",
      label: "Rental real estate, royalties, partnerships, S corporations, trusts, etc.",
      line: "Line 5",
      isCurrency: true,
      isPositive: false,
      hasHelp: true
    },
    {
      id: "netOperatingLoss",
      fieldName: "form1[0].Page1[0].Line8a_ReadOrder[0].f1_12[0]",
      label: "Net operating loss",
      line: "Line 8a",
      isCurrency: true,
      isPositive: true,
      hasHelp: true
    },
    {
      id: "carryoverLoss",
      fieldName: "",
      label: "Carryover loss",
      line: "Carryover loss",
      description: "If the borrower owns more than one business, ensure the Carryover Loss provided relates to the business you want Income Calculator to evaluate.",
      isCurrency: true,
      isPositive: true,
      isEmptyByDefault: true,
      hasHelp: true
    },
  ]
}

