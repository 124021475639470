<template>
  <div>

      <div class="row">
        <div class="col-md-6 pr-5">
          <h1 class="mt-0 se-std-bp-title-sb mb-3">Input Data: Form 1065 Sch K-1 ({{this.taxFormYear}})
            <a class="btn-link se-page-help-icon" v-tooltip.top-center="'Learn More'" @click="showPageHelp = true">
              <i class="fa fa-info-circle"></i>
            </a>
          </h1>

          <form autocomplete="off">
            <div class="bp-form-group mr-5"
                 v-for="(row, index) in tableData">

              <template v-if="row.id === 'isBorrowerTrustGrantor'">
                <p>
                  Most Partnerships are owned by individual (person) shareholders.
                  While it is not common, some Partnerships are owned by Trusts, S-Corporations or other Partnerships.
                </p>
                <p>
                  Compare the information on the loan application to the K-1.
                </p>
                <template v-if="row.pageHeader">
                  <span v-html="row.pageHeader"></span>
                </template>
                <br>
                <p>
                  If the K-1 Partner’s name and Identifying number match the name and Social Security Number of a borrower on the loan, report the K-1 information below.
                </p>
                <p>
                  If the K-1 Partner's Identifying number does not match the Social Security Number of a borrower on the loan, do not report K-1 information below.
                  <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="showHeaderHelp = true">
                    <i class="fa fa-info-circle"></i>
                  </a>
                </p>
              </template>

              <template v-if="!isHidden[index]">
                <label :for="row.id" class="se-std-field-group-label">
                    <span>{{row.line}}</span>
                    <a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="handleFieldHelp(row.id)" v-show="row.hasHelp">
                      <i class="fa fa-info-circle"></i>
                    </a>
                  </label>
              </template>

                <div @mouseover="highlightHoveredOverFormField(row)" @mouseleave="highlightHoveredOverFormField()">
                  <template v-if="row.id === 'isBorrowerTrustGrantor'">
                    <p>
                      <radio label="Yes"
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mb-1"><span class="se-std-label">The K-1 Partner’s identifying number matches the Social Security Number of the borrower and the K-1 Partner’s name is a revocable living trust where the borrower is the grantor</span>
                      </radio>
                      <radio label="No"
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">The K-1 Partner’s identifying number does not match the Social Security Number the borrower or the borrower is not the grantor of a revocable living trust</span>
                      </radio>
                      <radio label=""
                             v-model="row.value"
                             :disabled="isSkipped[index] || isLocked"
                             :class="{'opacity-5': isSkipped[index]}"
                             class="mt-1"><span class="se-std-label">Not Applicable</span>
                      </radio>
                    </p>
                  </template>

                  <template v-else-if="row.id === 'taxPeriodEndDate'">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">{{row.name}}</span>
                    </radio>
                    <masked-input :id="row.id"
                                  class="se-tax-field-input ml-4 bp-form-input"
                                  type="text"
                                  :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                                  :guide="false"
                                  :pipe="dateMaskPipe"
                                  @focus="highlightFocusedFormField(row)"
                                  @blur="fieldIsFocused = false;"
                                  :disabled="row.isEmpty || isSkipped[index]"
                                  placeholder="MM/DD/YYYY"
                                  v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no date on this line</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isFinalK1'">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes, The checkbox for {{row.line}} checked</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No, The checkbox for {{row.line}} is not checked</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isFinalReturnOverride' && !isHidden[index]">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The loan file contains documentation the business is open and operating as a Partnership</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">This is the Partnership’s final return, it will no longer exist, or the business structure has changed</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'partnerType'">
                    <radio label="generalPartner"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">{{Enums.SePartnerType.generalPartner}}</span>
                    </radio>
                    <radio label="limitedPartner"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">{{Enums.SePartnerType.limitedPartner}}</span>
                    </radio>
                    <radio label="none"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">{{Enums.SePartnerType.none}}</span>
                    </radio>
                  </template>


                  <template v-else-if="row.id === 'distributionCodeA'">
                    <p>
                      Look at the codes for any amounts listed on {{row.line}}. Are any codes “A”?
                    </p>
                    <h4>
                      {{row.name}}
                    </h4>
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">Yes, one of the codes on {{row.line}} is “A”</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">No, there are no codes on {{row.line}} or none of the codes is “A”</span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'isBusinessLiquidityAdequate' && !isHidden[index]">
                    <p>
                      {{appName}} determines the Monthly Qualifying Income based on the history of business distributions and, if necessary, a business liquidity analysis.
                    </p>
                    <p>
                      Fannie Mae permits the lender to use an alternate method (outside of {{appName}}) to confirm the business has adequate liquidity to support the withdrawal of earnings. If {{appName}} determines the business liquidity needs lender review, select one of the following descriptions:
                    </p>
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">If necessary, the lender will confirm the business has adequate liquidity to support the withdrawal of earnings outside of {{appName}}. A message will be displayed in the Findings Report when this is required.<a class="se-help-icon btn-link" v-tooltip.top-center="'Learn More'" @click="showLiquidityHelp = true"><i class="fa fa-info-circle"></i>
                  </a></span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">The lender is unable to confirm the business has adequate liquidity to support the withdrawal of earnings. </span>
                    </radio>
                  </template>

                  <template v-else-if="row.id === 'guaranteedPaymentsToPartnersAlternative' && !isHidden[index]">
                    <radio label="Yes"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1"><span class="se-std-label">The borrower (1) acquired an ownership share in a professional services partnership or LLC (for example, a medical practice or a law firm) in {{ getCalPy }}, and (2) can provide their partnership agreement. (3) Guaranteed payments for services and/or capital have been paid in {{getCalCy}} in an amount consistent with {{getCalPy}} and (4) can be supported by a {{getCalCy}} year-to-date paystub or other evidence of {{getCalCy}} year-to-date receipt.</span>
                    </radio>
                    <radio label="No"
                           v-model="row.value"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">(1a) The borrower did not acquire their ownership share in this business in {{getCalPy}}, or (1b) the business is not a  professional services partnership or LLC (for example, a medical practice or a law firm), or (2) the borrower cannot provide their partnership agreement, or (3) Guaranteed payments for services and/or capital have not been paid in {{getCalCy}} in an amount consistent with {{getCalPy}} or (4) cannot be supported by evidence of current year-to-date receipt.</span>
                    </radio>
                  </template>

                  <template v-else-if="!isHidden[index]">
                    <radio :label="false"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mb-1">
                      <span class="se-std-label">Enter {{row.name}}</span>
                    </radio>
                    <input :id="row.id"
                           :readonly="isLocked"
                           class="se-tax-field-input ml-4 bp-form-input bp-u-shadow-04"
                           type="text"
                           v-mask="row.mask"
                           @focus="highlightFocusedFormField(row)"
                           @blur="fieldIsFocused = false"
                           :disabled="row.isEmpty || isSkipped[index]"
                           v-model="row.value" />
                    <radio :label="true"
                           v-model="row.isEmpty"
                           :disabled="isSkipped[index] || isLocked"
                           :class="{'opacity-5': isSkipped[index]}"
                           class="mt-1"><span class="se-std-label">There is no {{row.isCurrency ? 'amount' : 'percent'}} on this line</span>
                    </radio>

                    <div class="bp-help is-error" v-if="row.id === 'partnerShareOfCapitalEndingPct' && $v.partnerShareOfCapitalEndingPct.$error">
                      <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg></div>
                      <template v-if="!$v.partnerShareOfCapitalEndingPct.withinLimits">Must be between 1.0 and 100.0</template>
                    </div>

                  </template>

                </div>
            </div>
          </form>

          <div class="bp-help is-error mb-2" v-if="$v.allAnswered.$error">
            <div class="bp-ucon" data-src="feedback-warning"><svg class="bp-svg" viewBox="0 0 24 24" id="feedback-warning" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.009 13.987V8.934h-1.956v5.053h1.956zm0 4.016v-1.962h-1.956v1.962h1.956zM2.988 20.962L12.016 3l9.025 17.962H2.988z"></path></svg>
            </div>
            <template v-if="!$v.allAnswered.required">Provide answer for all items or select 'There is no amount on this line'</template>
          </div>

        </div>

        <div class="col-md-6 d-none d-sm-none d-md-block">
          <div class="std-pdf-row">
          <WebViewer :url="this.pdf"
                     ref="viewer"
                     :se-fields="seFields"
                     :tax-form-year="this.taxFormYear"
                     :tax-form-id="this.taxFormId"
                     @viewerLoaded="onViewerLoaded"
                     :key="this.pdfViewerKey"/>
          </div>
        </div>
      </div>

    <se-help :visible.sync="showPageHelp">
      <p>
        Use this screen to provide information from the borrower’s K-1 (1065), Partner’s Share of
        Income, Deductions, Credits, etc. For a Partnership, the business owners are referred to as Partners.
      </p>
      <p>
        Q. The borrower satisfies all the conditions to waive business tax returns, and I did not collect them.
        Do I still need to provide information from the Partnership K-1 form?<br>
        A. Even if business tax returns are waived, you must still provide {{ appName }} certain data from the borrower’s K-1 form.
      </p>
      <p>
        <icon-base width="22" height="22" iconColor="#0c77ba"><icon-flag/></icon-base>
        {{ appName }} may collect information not traditionally used in evaluating self-employment income. Some data may be used to
        validate data integrity or determine if income from other tax return forms or schedules may be needed.
      </p>
    </se-help>

    <se-help :visible.sync="showHeaderHelp">
      <b>FAQs:</b>
      <p>
        Q. The Partner’s name or identifying number in Part II is a business, how does income from this business get reported?<br>
        A. Some tax returns are very complex and include situations where businesses are owned by other businesses.
        For example, the restaurant “Café Royale” is a partnership. The partners are an S-Corporation named “Menu Consulting” and a Partnership named “CR2 LLC.”
        <ul>
          <li>
            For “Café Royale,” Part I of Form K-1 should show the business name as “Café Royale,” and Part II of Form K-1 should show the partners as
            “Menu Consulting” and “CR2 LLC,” {{ appName }} cannot evaluate income from this business because the individual (person) borrower is not shown
            as an owner in Part II of the K-1s.  Any W-2 wages paid to the borrower from “Café Royale” should be treated as non-self-employment income on the loan application.
          </li>
          <li>
            For “Menu Consulting,” Part II of Form K-1 should show the borrower’s individual (person) name and Social Security Number.
            {{ appName }} can evaluate income from this business based on the individual (person) borrower's percentage of ownership.
          </li>
          <li>
            For “CR2 LLC,” Part II of Form K-1 should show the borrower’s individual (person) name and Social Security Number.
            {{ appName }} can evaluate income from this business based on the individual (person) borrower's percentage of ownership.
          </li>
        </ul>
      </p>
    </se-help>

    <se-help :visible.sync="showLiquidityHelp">
      <b>FAQs:</b>
      <p>
        Q. Why is this important to {{ appName }}?<br>
        A. When this option is selected, if {{ appName }} determines there is not sufficient
        business liquidity to enable the borrower to withdraw earnings (using the method outlined in
        the Fannie Mae Selling Guide), the business earnings will still be included as monthly qualifying
        income and a message will be generated for the lender to document their alternative calculation method.
      </p>
      <p>
        Q. What alternative business liquidity calculation methods are acceptable to Fannie Mae?<br>
        A. Fannie Mae allows the lender to use its discretion in determining the method to confirm that the
        business has adequate liquidity to support the withdrawal of earnings; specific alternative formulas are not provided.
      </p>

      <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
      <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.3-07/income-or-loss-reported-irs-form-1065-or-irs-form-1120s-schedule-k-1">Learn more about evaluating business liquidity in the Income or Loss Reported on IRS Form 1065 or IRS Form 1120S, Schedule K-1 section of the Fannie Mae Selling Guide.</a>
    </se-help>


    <se-help :visible.sync="showFieldHelp.isBorrowerTrustGrantor">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. The K-1 is in the name of a trust. How do I determine whether the borrower is a grantor of a revocable living trust?<br>
          A. You may need to collect and review documentation from the borrower, such as the trust agreement.
        </p>
        <p>
          Q. The K-1 is in the name of a trust. Does the property securing the mortgage need to be held by a trust to use the income from this business for qualifying the loan?<br>
          A. No. Income received from a trust is different than holding title to a property in a trust.
        </p>
        <p>
          Q. The K-1 is in the name of a trust. Does the K-1 trust need to meet the eligibility requirements in the Fannie Mae Selling Guide for Inter Vivos (Living) Revocable Trusts?<br>
          A. No. Income received from a trust is different than holding title to a property in a trust.
        </p>

      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.taxPeriodEndDate">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. This date is used to determine if the business operates on a calendar year (January 1 to December 31) or a non-calendar fiscal year.
          Typically, any date other than December 31 indicates the business operates on a non-calendar fiscal year.
          Currently {{ appName }} cannot evaluate income from a business that operates on a non-calendar fiscal year.
        </p>
        <p>
          Q. There is no date on this line of the tax returns. Is that common? Should I provide 12/31 as the date?<br>
          A. Most businesses operate on a calendar year. If there is no date on this line of the tax return this typically means the business operates on a
          calendar year and you should select “There is no date on this line.”
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.isFinalK1">
      <div class="se-field-help">
        <p>
          When the “Final K-1” checkbox is checked on the tax returns, it means the partner (borrower) will no longer receive income from the Partnership.
          If you select “Yes, the checkbox for Final K-1 is checked,” you will be given an opportunity to identify if the loan file contains documentation
          that the business is open and operating as a Partnership.
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. The income must be stable and show a likelihood of continuance in order to be used for loan qualification.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Factors to Consider for a Self-Employed Borrower (and income stability)
          in the <a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.2-01/underwriting-factors-and-documentation-self-employed-borrower">Underwriting Factors and Documentation for a Self-Employed Borrower</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. The “Final K-1” checkbox is checked on the K-1 Form, but the business has not closed and is not closing.
          The Partnership has changed (or is changing) to/from another business structure, such as a Corporation or S-Corporation.
          What information should I provide to {{ appName }}? How do I get {{ appName }} to evaluate this income?<br>
          A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax returns.
          When you select “Yes, the checkbox for final K-1 is checked,” an additional question will appear. Make the appropriate selection for this additional question.
          {{ appName }} cannot be used to evaluate income when the business structure changes during the tax period it
          is evaluating. You may use another method to evaluate the borrower’s income.
        </p>
        <p>
          Q. I think the K-1 Form may have an issue. The “Final K-1” checkbox is
          checked on the K-1 Form, but the business has not closed and is not closing. What information should I provide
          to {{ appName }}? Do I need to notify the borrower of this potential error? How do I get {{ appName }} to evaluate this income?<br>
          A. You should provide the information to {{ appName }} exactly as it appears on the borrower’s tax
          returns. You are not required to notify the borrower of any potential errors as part of using {{ appName }}. If you select “Yes,
          the checkbox for Final K-1 is checked”, you will be given an opportunity to identify if the loan file contains
          documentation that the business is open and operating as a Partnership.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          {{ appName }} should not be relied upon for tax advice.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.partnerType">
      <div class="se-field-help">
        <p>
          The borrower’s personal liability for business debts determines if an owner is a General Partner or Limited Partner.
        </p>
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
         </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.partnerShareOfCapitalEndingPct">
      <div class="se-field-help">
        <p>
          The Partnership determines each owner’s year-end share of the business and indicates it on Line J as Capital Ending (%).
        </p>
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{ appName }} uses this percentage to determine:
          <ul>
          <li>if business tax returns may not be required for income documentation</li>
          <li>the borrower’s share of business income in the {{ appName }} Findings Report.</li>
        </ul>
        <p>
          Q. The percentage on this line is less than 25%. Do I need the borrower’s complete business tax returns to document income?<br>
          A. No. Any individual who has less than 25% ownership interest in a business is not considered to be self-employed according to the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Other Sources of Income (and Schedule K-1 Income) in the<a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income"> Income Assessment</a> section of the Fannie Mae Selling Guide.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most self-employed borrowers with partnership income have a Capital Ending percentage to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.partnerShareOfLiabilitiesRecourseEnding">
      <div class="se-field-help">
        <p>
          The Partnership determines each partner’s year-end share of the business’ liabilities with recourse and indicates it on Line K as Recourse Ending (%).
        </p>
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.partnerEndingCapitalAccount">
      <div class="se-field-help">
        <p>
          The Partnership determines each partner's year-end share of the business’ capital and indicates the amount on Line L as Ending Capital Account ($).
        </p>
        <b>FAQs:</b>
        <p>
           Q. I’m not used to considering this information when I evaluate self-employment income.
          Will {{ appName }} be using this information in its monthly qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income.
          {{ appName }} may collect information not traditionally used in evaluating self-employment income.
          Some information may be used to validate data integrity or determine if income from other tax return forms or schedules may be needed.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ordinaryBusinessIncomeLoss">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be considered in the monthly qualifying income calculation.
        </p>
        <p>
          Q. There is no amount on this line. Is that common?<br>
          A. No. Most self-employed borrowers with partnership income have Ordinary business income or loss to report. Please double-check the line entry.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.netRentalRealEstateIncomeLoss">
      <div class="se-field-help">
        <p>
          This version of {{ appName }} cannot evaluate a Partnership with rental income or loss.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.otherNetRentalIncomeLoss">
      <div class="se-field-help">
        <p>This version of {{ appName }} cannot evaluate a Partnership with rental income or loss.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.itemsAffectingShareholderBasisCodeDAmount">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount on this line will be considered in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>
    <se-help :visible.sync="showFieldHelp.guaranteedPaymentsForServices">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Does {{ appName }} treat Guaranteed payments for services differently than Guaranteed payments for capital?<br>
          A. No. {{ appName }} treats all guaranteed payments to partners equally. All
          guaranteed payments to partners will be included in the calculation of monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.guaranteedPaymentsForCapital">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income.
          Does {{ appName }} treat Guaranteed payments for services differently than Guaranteed payments for capital?<br>
          A. No. {{ appName }} treats all guaranteed payments to partners equally. All
          guaranteed payments to partners will be included in the calculation of monthly qualifying income.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.ordinaryDividends">
      <div class="se-field-help">
        <b>FAQs:</b>
        <p>
          Q. I’m not used to considering this information when I evaluate self-employment income. Will {{ appName }} be using this information in its monthly
          qualifying income calculation?<br>
          A. No. {{ appName }} will not use this information when it calculates the monthly qualifying income. {{ appName }} may collect information not traditionally
          used in evaluating self-employment income. Some information may be used to validate data integrity or determine if income from other tax return forms or
          schedules may be needed.
        </p>
        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. {{  appName  }} does not include dividends paid by the business in its evaluation. When Ordinary dividends are shown on Schedule K-1,
          {{ appName }} will generate a reminder message that dividends may be used as qualifying income in accordance with the Fannie Mae Selling Guide.
          Dividend income is reported to the IRS on Schedule B of the borrower’s individual (personal) tax returns. Eligible dividend income used for loan
          qualification should be reported to Desktop Underwriter as dividend income.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Other Sources of Income (including Interest and Dividends) in the<a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income"> Income Assessment</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.distributionCodeAAmount">
      <div class="se-field-help">
        <b>FAQs:</b>

        <p>
          Q. Why is this important to {{ appName }}?<br>
          A. Any amount coded as distributions on this line will be considered in the monthly qualifying income calculation.
        </p>
      </div>
    </se-help>

    <se-help :visible.sync="showFieldHelp.guaranteedPaymentsToPartnersAlternative">
      <div class="se-field-help">
        <b>FAQs:</b>

        <p>
        Q. Why is this information important to {{ appName }}?<br>
		A. {{ appName }} uses this information to determine if the borrower may be eligible for the exception
          to the two year requirement of receiving Guaranteed Payments to Partners described in the Fannie Mae Selling Guide.
        </p>
        <p>
          <icon-base width="22" height="22" iconColor="#0c77ba"><icon-book/></icon-base>
          Learn more about Schedule K-1 income (including Guaranteed Payments to Partners) in the<a target="_blank" href="https://selling-guide.fanniemae.com/sel/b3-3.1-09/other-sources-income"> Other Sources of Income</a> section of the Fannie Mae Selling Guide.
        </p>
      </div>
    </se-help>

    <se-buttons
      :display-submit="false"
      :display-save="false"
      :disable-next="false"
      :disable-previous="false"
      @seNext="formNext"
      @sePrevious="formPrevious">
    </se-buttons>

  </div>

</template>
<script>
import SeButtons from './SeButtons.vue'
import SeHelp from './SeHelp.vue'
import {Checkbox, Radio} from 'src/components/index'
import LSwitch from 'src/components/Switch.vue'
import { Collapse, CollapseItem } from 'element-ui'
import WebViewer from 'src/components/WebViewerROC.vue'
import {Table, TableColumn, Drawer} from 'element-ui'
import {required, requiredIf} from "vuelidate/lib/validators";
import { taxSingleFormMixin } from 'src/mixins/TaxSingleFormMixin'
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import Enums from "@/model/enums";

import appNameMixin from '../../../mixins/AppNameMixin'
import Swal from "sweetalert2";

export default {
  mixins: [taxSingleFormMixin, appNameMixin],
  components: {
    Checkbox,
    Radio,
    LSwitch,
    SeButtons,
    SeHelp,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    WebViewer,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      showHeaderHelp: false,
      showLiquidityHelp: false,
    }
  },
  validations: {
    allAnswered: {
      required: (v) => v
    },
    partnerShareOfCapitalEndingPct: {
      withinLimits(field) {
        return this.withinLimitsOrEmpty(field, 1, 100.0);
      }
    }
  },
  computed: {
    dateMaskPipe() {
      return createAutoCorrectedDatePipe('mm/dd/yyyy', {
        minYear: 2018,
        maxYear: parseInt(this.taxFormYear)
      })
    },
    isSkipped() {
      return this.tableData.map(f => {
        if (this.skippedForm) return true
        let skipped = false;
        if (f.id === "isFinalReturnOverride") {
          skipped = "No" === this.getValue("isFinalK1")
        }
        if (f.id === "isBusinessLiquidityAdequate") {
          skipped = !this.displayBusinessLiquidityConfirmation
        }
        if (f.id === "guaranteedPaymentsToPartnersAlternative") {
          skipped = !this.displayGuaranteedPaymentsToPartnersAlternative
        }
        return skipped;
      })
    },
    isHidden() {
      return this.tableData.map((f,index) => {
        if (["isFinalReturnOverride", "isBusinessLiquidityAdequate", "guaranteedPaymentsToPartnersAlternative"].includes(f.id)) {
          return this.isSkipped[index]
        }
        return false
      })
    },
    partnerShareOfCapitalEndingPct() {
      return this.getField('partnerShareOfCapitalEndingPct')
    },
    displayBusinessLiquidityConfirmation() {
      return this.getPY1 === this.taxFormYear
        && this.notEqualZeroWithTolerance(this.getValueAsNumber("ordinaryBusinessIncomeLoss")
          + this.getValueAsNumber("netRentalRealEstateIncomeLoss")
          + this.getValueAsNumber("otherNetRentalIncomeLoss"))
    },
    displayGuaranteedPaymentsToPartnersAlternative() {
      let guaranteedPaymentsForServicesField = this.getField('guaranteedPaymentsForServices')
      let guaranteedPaymentsForCapitalField = this.getField('guaranteedPaymentsForCapital')

      return this.getPY1 === this.taxFormYear
        && this.isEmploymentOwnershipInterestProvidedLt25
        && this.seRequest.extraData.employmentOwnershipRecentAcquisitionIndicator
        && this.seRequest.extraData.lastYearTaxReturnFiled
        && this.getEmploymentMonthsCount >= 24
        && !this.partnerShareOfCapitalEndingPct.isEmpty && this.partnerShareOfCapitalEndingPct.value && this.getValueAsNumber(this.partnerShareOfCapitalEndingPct.id) < 25
        && (!guaranteedPaymentsForServicesField.isEmpty && guaranteedPaymentsForServicesField.value && this.getValueAsNumber(guaranteedPaymentsForServicesField.id) !== 0
          || !guaranteedPaymentsForCapitalField.isEmpty && guaranteedPaymentsForCapitalField.value && this.getValueAsNumber(guaranteedPaymentsForCapitalField.id) !== 0)
    },
    isBorrowerTrustGrantor() {
      return this.getValue("isBorrowerTrustGrantor")
    },
  },
  watch: {
    displayBusinessLiquidityConfirmation(display) {
      let f = this.getField('isBusinessLiquidityAdequate');
      if (f) {
        if (display) {
          if (f.isEmpty) {
            f.value = 'Yes'
          }
          f.isEmpty = false
        } else {
          f.isEmpty = true
          f.value = ''
        }
      }
    },
    isBorrowerTrustGrantor(value) {
      let f = this.getField('isBorrowerTrustGrantor')
      if (f) {
        if (value === "") {
          f.isEmpty = true
        } else {
          f.isEmpty = false
        }
      }
    }
  },
  methods: {
    handleHelp(index, row) {
      row.showHelp = true;
    },
    formNext() {
      if (this.notEqualZeroWithTolerance(this.getValueAsNumber("netRentalRealEstateIncomeLoss")) || this.notEqualZeroWithTolerance(this.getValueAsNumber("otherNetRentalIncomeLoss"))) {
        this.alertRentalIncome(this.next);
        return;
      }
      this.next()
    },
    formPrevious() {
      if (this.notEqualZeroWithTolerance(this.getValueAsNumber("netRentalRealEstateIncomeLoss")) || this.notEqualZeroWithTolerance(this.getValueAsNumber("otherNetRentalIncomeLoss"))) {
        this.alertRentalIncome(this.previous);
        return;
      }
      this.previous()
    },
    alertRentalIncome(moveToPage) {
      let msgIcon = "warning"
      let msgHtml = "<p>This version of Income Calculator cannot evaluate self-employment income generated from rental properties.</p>"
        + "<p>You may continue to provide tax return data for informational purposes, but Income Calculator will not return a qualifying income amount.</p>"

      Swal.fire({
        title: 'WARNING!',
        html: msgHtml,
        width: 480,
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {confirmButton: 'bp-button bp-button--primary', cancelButton: 'bp-button'},
        confirmButtonText: `OK`,
        icon: msgIcon
      }).then((result) => {
        if (result.isConfirmed) {
          moveToPage()
        }
      })
    }

  }
}
</script>
<style >

.required > div:after {
  content:" *";
  color: red;
  text-nowrap: true
}

.std-pdf-row {
  position: fixed;
  width: 40%;
  height: 90%;
}


</style>
